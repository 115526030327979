/* eslint-disable react/no-children-prop */
import { DrawerHeader, Drawer, DrawerBody, DrawerFooter } from '@wow/drawer';
import { Icon, Box, List, Text, Button, Spinner } from '@chakra-ui/react';
import { Divider } from '@wow/divider';
import { Download } from 'react-feather';
import React from 'react';
import { themeChakra } from '@wow/themes';
import { DetailDrawerProps } from './interface';
import { GlobalStyle, StyledListItem, LoadingScreenStyles } from './styled';
import messages from '../../../../lang/es.json';

const {
  colors: { primary },
} = themeChakra;

function DetailDrawerComponent({
  isOpen,
  onClose,
  icon,
  nombreBanco,
  titleDrawer,
  buttonPrimary,
  listItems = [],
  isLoading,
}: DetailDrawerProps & { isLoading: boolean }) {
  return (
    <>
      <GlobalStyle />
      <Drawer isOpen={isOpen} onClose={onClose} size={'lg'}>
        {isLoading ? (
          <div>
            <LoadingScreenStyles>
              <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor={primary[100]}
                color={primary[400]}
                size={'xl'}
                variant='default'
                className='spinnerClass'
              />
              <Text className='title'>{messages.loadingScreen.title}</Text>
              <Text className='description'>{messages.loadingScreen.description}</Text>
            </LoadingScreenStyles>
          </div>
        ) : (
          <>
            <DrawerHeader>
              <DrawerHeader.Title>{titleDrawer}</DrawerHeader.Title>
            </DrawerHeader>
            <DrawerBody>
              <List listStyleType='none'>
                <StyledListItem>
                  <Text textStyle='bodyMd'>{messages.detailDrawer.bank}</Text>
                  <Box className='bank'>
                    <img className='imgLogo' src={icon} alt={''} />
                    <Text textStyle='bodyMd'>{nombreBanco}</Text>
                  </Box>
                </StyledListItem>
                <Divider orientation='horizontal' variant='light' />
                {listItems.map(item => (
                  <React.Fragment key={item.id}>
                    <StyledListItem>
                      <Text textStyle='bodyMd'>{item.label}</Text>
                      <Text textStyle='bodyMd'>{item.value}</Text>
                    </StyledListItem>
                    <Divider orientation='horizontal' variant='light' />
                  </React.Fragment>
                ))}
              </List>
            </DrawerBody>
            <Divider orientation='horizontal' variant='light' />
            <DrawerFooter>
              <Button variant={'primary'} rightIcon={<Icon as={Download} />}>
                {buttonPrimary}
              </Button>
            </DrawerFooter>
          </>
        )}
      </Drawer>
    </>
  );
}

export default DetailDrawerComponent;
