/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-no-useless-fragment */
// eslint-disable-next-line import/no-unresolved
import { HeaderBase } from '@react-ib-mf/header-menu-ui';
import { useEffect, useState, useContext } from 'react';
import { useIsFetching } from 'react-query';
import { isCheckOTP, pushAnalyticsEvent, UnActivatedToken } from '@react-ib-mf/style-guide-ui';
import imgEmptyState from '../assets/images/Binoculars.png';
import ChipsExtractos from '../components/ChipsExtractos';
import ScreenError from '../components/ScreenError/ScreenError';
import TablaExtractos from '../components/TablaExtractos';
import { ITablaExtractos } from '../interfaces/IResponseTable';
import { AppContext } from '../provider/AppContextProvider';
import DateLabel from './Styled';
import messages from '../lang/es.json';

function Extractos() {
  const [isSearch, setIsSearch] = useState(false);
  const [dataTableInput, setDataTableInput] = useState<ITablaExtractos>({
    input: {
      numeroCuenta: '',
      tipoCuenta: '',
      codigoBanco: '',
      moneda: '',
      fechaInicial: '',
      fechaFinal: '',
    },
    denominacion: '',
    nombreBanco: '',
  });
  const { input } = dataTableInput;

  const isFetchingDataTable = useIsFetching(['GetDataTableExtractos']);

  const { chipExtractosFetched, getChipData, lastDateUpdated, errorDataExtractos, showDateExtractos } = useContext(AppContext) as any;
  getChipData();

  useEffect(() => {
    isFetchingDataTable === 0 && setIsSearch(false);
  }, [isFetchingDataTable]);

  useEffect(() => {
    pushAnalyticsEvent({
      event: 'trackContentGroup',
      content_group: 'Extractos',
    });
  }, []);

  return (
    <>
      <HeaderBase
        title={messages.title}
        tooltipText=''
        tooltipTextComponent={<p style={{ margin: '5px' }}>{messages.tooltipTextMain}</p>}
        breadcrumb={messages.breadcrumbOptions}
      />
      {isCheckOTP() ? (
        <>
          {!isFetchingDataTable && showDateExtractos && !errorDataExtractos ? (
            <DateLabel>
              {messages.dateText}
              <span>{lastDateUpdated}</span>
            </DateLabel>
          ) : (
            ''
          )}

          {!isFetchingDataTable && errorDataExtractos && (
            <ScreenError title={messages.screenErrorText.title} description={messages.screenErrorText.description} image={imgEmptyState} />
          )}

          {chipExtractosFetched && (
            <>
              <ChipsExtractos
                onChangeChips={e => {
                  e.input.fechaFinal !== '' &&
                    setDataTableInput({
                      input: { ...e.input, cantidadRegistros: input.cantidadRegistros, pagina: input.pagina },

                      denominacion: e.denominacion,
                      nombreBanco: e.nombreBanco,
                    });
                }}
                onSearch={() => setIsSearch(true)}
                isFetching={isFetchingDataTable === 1}
              />
              {input.fechaInicial !== '' && <TablaExtractos isSearch={isSearch} data={dataTableInput} />}
            </>
          )}
        </>
      ) : (
        <UnActivatedToken />
      )}
    </>
  );
}
export default Extractos;
