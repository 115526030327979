// eslint-disable-next-line import/no-unresolved
import styled from 'styled-components';
import { themeChakra } from '@wow/themes';
import { COLOR_DESCRIPTION_EMPTY_STATES, COLOR_TITLE_EMPTY_STATES } from '../designTokens/colors';

import { IEmptyStates } from '../interfaces/IEmptyStates';
import messages from '../lang/es.json';

const {
  textStyles: { bodyLg, h4Bold },
} = themeChakra;

function EmptyStates(props: IEmptyStates) {
  const { title, description, image } = props;

  return (
    <Container>
      <div className='image'>
        <img src={image} alt={messages.emptyState.alt} />
      </div>
      <div className='title'>
        <p>{title}</p>
      </div>
      <div className='description'>{description}</div>
    </Container>
  );
}

export default EmptyStates;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;

  .image {
    margin-bottom: 28px;
  }
  .title {
    font-family: ${h4Bold.fontFamily};
    font-weight: ${h4Bold.fontWeight};
    font-size: ${h4Bold.fontSize};
    line-height: ${h4Bold.lineHeight};
    letter-spacing: ${h4Bold.letterSpacing};
    color: ${COLOR_TITLE_EMPTY_STATES};
    margin-bottom: 8px;
    p {
      margin: 0;
    }
  }
  .description {
    font-family: ${bodyLg.fontFamily};
    font-weight: ${bodyLg.fontWeight};
    font-size: ${bodyLg.fontSize};
    line-height: ${bodyLg.lineHeight};
    letter-spacing: ${bodyLg.letterSpacing};
    color: ${COLOR_DESCRIPTION_EMPTY_STATES};
  }
`;
