import { Button } from '@chakra-ui/react';

interface ICustomButton {
  isDisable?: boolean;
  text: string;
  icon?: JSX.Element;
  onClick: () => void;
}

function CustomButton({ isDisable, text, icon, onClick }: ICustomButton) {
  return (
    <Button size='sm' variant='primary' isDisabled={isDisable} onClick={onClick}>
      {icon !== undefined ? <div className='icon'>{icon}</div> : ''}
      {text}
    </Button>
  );
}

export default CustomButton;
