import { Tag } from '@wow/tag';
import { BaseSingleInputFieldProps, DateValidationError, FieldSection } from '@mui/x-date-pickers/models';
import { UseDateFieldProps } from '@mui/x-date-pickers/DateField';
import { Dayjs } from 'dayjs';
import { Dispatch, SetStateAction } from 'react';

interface TagFieldProps
  extends UseDateFieldProps<Dayjs, false>,
    BaseSingleInputFieldProps<Dayjs | null, Dayjs, FieldSection, false, DateValidationError> {
  setOpen?: Dispatch<SetStateAction<boolean>>;
  open?: boolean;
}

export default function TagField({ setOpen, label, id, disabled, open, value }: TagFieldProps) {
  return (
    <Tag
      style={{
        backgroundColor: 'rgb(214, 226, 255)',
        marginLeft: '6px',
        color: 'rgb(0, 46, 104)',
        fontWeight: '400',
        border: 'none',
      }}
      id={id}
      label={`${label} ${value?.format('DD/MM/YYYY') || ''}`}
      onClick={() => setOpen?.(prev => !prev)}
      isOpen={open}
      component='filter'
      size='lg'
      isSelected={!!value}
      isDisabled={disabled}
      data-testid={id}
    />
  );
}
