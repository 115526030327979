import dayjs, { Dayjs } from 'dayjs';
import styled from 'styled-components';
import { useState, useRef } from 'react';
import { DatePicker } from '@wow/date-picker';
import CustomDay from './CustomDay';
import TagField from './TagField';

interface TagDatepickerProps {
  label: string;
  value: Dayjs | null;
  setValue: (date: Dayjs | null) => void;
  disabled?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  minDateRange: dayjs.Dayjs;
  maxDateRange: dayjs.Dayjs;
  startDate: dayjs.Dayjs | null;
  maxEndDate: dayjs.Dayjs;
}
const DatePickerWrapper = styled.div``;

export default function TagDatepicker({
  label,
  value,
  setValue,
  disabled,
  minDate,
  maxDate,
  minDateRange,
  maxDateRange,
  startDate,
  maxEndDate,
}: TagDatepickerProps) {
  const [open, setOpen] = useState(false);
  const anchorEl = useRef(null);

  return (
    <DatePickerWrapper ref={anchorEl}>
      <DatePicker
        slots={{
          field: TagField,
          day: CustomDay(label, minDateRange, maxDateRange, startDate, maxEndDate),
        }}
        slotProps={{
          field: { setOpen, open, value } as any,
          popper: { anchorEl: anchorEl.current },
        }}
        label={label}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        value={value}
        onChange={setValue}
        disabled={disabled}
        minDate={minDate}
        maxDate={label === 'Hasta' ? maxDate : undefined}
      />
    </DatePickerWrapper>
  );
}
