/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useMemo, createContext } from 'react';
import { useQuery } from 'react-query';
import { getBancos } from '../services/apiExtractos';

export const AppContext = createContext({});
export default function AppContextProvider({ children }: any) {
  const [chipExtractosFetched, setChipExtractosFetched] = useState(false);
  const [reportExtractosFetched, setReportExtractosFetched] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [lastDateUpdated, setLastDateUpdated] = useState(null);
  const [errorDataExtractos, setErrorDataExtractos] = useState(false);
  const [showDateExtractos, setShowDateExtractos] = useState(false);

  const response = useQuery(['GetBanks'], () => getBancos().then(res => res.data));

  const getChipData = () => {
    if (response.data) {
      setErrorDataExtractos(response.isError);
      setChipExtractosFetched(true);
      return response;
    } else {
      setChipExtractosFetched(false);
      if (response.isError) {
        setErrorDataExtractos(response.isError);
      }
    }
  };

  const value = useMemo(
    () => ({
      chipExtractosFetched,
      setChipExtractosFetched,
      getChipData,
      reportExtractosFetched,
      setReportExtractosFetched,
      loadingReport,
      setLoadingReport,
      lastDateUpdated,
      setLastDateUpdated,
      errorDataExtractos,
      setErrorDataExtractos,
      showDateExtractos,
      setShowDateExtractos,
    }),
    [
      chipExtractosFetched,
      setChipExtractosFetched,
      getChipData,
      loadingReport,
      setLoadingReport,
      reportExtractosFetched,
      setReportExtractosFetched,
      lastDateUpdated,
      setLastDateUpdated,
      errorDataExtractos,
      setErrorDataExtractos,
      showDateExtractos,
      setShowDateExtractos,
    ],
  );
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
