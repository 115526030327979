import styled from 'styled-components';
import { themeChakra } from '@wow/themes';
import {
  BACKGROUND_COLUMN_CHECKBOX,
  BORDER_COLOR_COLUMN_CHECKBOX,
  COLOR_TITLE_COLUMN_CHECKBOX,
  COLOR_TITLE_NOT_COLUMN_CHECKBOX,
} from '../../../../designTokens/colors';

interface validate {
  prop: boolean;
}

const {
  textStyles: { labelLgBold, labelLg },
} = themeChakra;

const CheckBox = styled.input`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: ${BACKGROUND_COLUMN_CHECKBOX};
  border: 1px solid ${BORDER_COLOR_COLUMN_CHECKBOX};
  border-radius: 4px;
  appearance: none;
`;

const Label = styled.label``;

const TitleCheckBox = styled.div<validate>`
  font-family: ${labelLgBold.fontFamily};
  font-weight: ${props => (props.prop ? labelLgBold.fontWeight : labelLg.fontWeight)};
  font-size: ${labelLgBold.fontSize};
  line-height: ${labelLgBold.lineHeight};
  letter-spacing: ${labelLgBold.letterSpacing};
  color: ${props => (props.prop ? COLOR_TITLE_COLUMN_CHECKBOX : COLOR_TITLE_NOT_COLUMN_CHECKBOX)};
`;

const WrapperCheckBox = styled.div``;

export { CheckBox, Label, TitleCheckBox, WrapperCheckBox };
