// eslint-disable-next-line import/no-unresolved
import { getToken } from '@react-ib-mf/style-guide-ui';
import axios from 'axios';

import mockDataExtractos from '../../test/__mocks__/extractos/responseTable';
import { IDataFiltros } from '../interfaces/IPropsCustomSelect';
import { IDataMovimentDetail, IDataMovimentDetailResponse, IDataTable, IDataTableInput } from '../interfaces/IResponseTable';
import { IExtractosReporte } from '../interfaces/IApiExtractos';
import { IResponseData } from '../interfaces/IResponseData';
import verifyTypeReport from '../utils/apiExtractosUtils';
/*
// No se debe setear el axios.defaults.URLBase ya que pisa la url de mf-home-interna
*/

axios.defaults.headers.common.Authorization = `Bearer ${getToken()}`;
axios.defaults.headers.common.user_key = process.env.API_USER_KEY ?? '';

const getBancos = () => {
  const fullUrl = `${process.env.CONSULTIVO_API_BASE_URL}extractos/obtenerDatosParaFiltro`;
  return axios.get<IResponseData<IDataFiltros>>(fullUrl);
};
// extractos/obtenerDatosResultadoExtractos/numeroCuenta/tipoCuenta/codigoBanco/moneda/fechaInicial/fechaFinal/1/2
const getDataTableExtractos = (params: IDataTableInput) => {
  const { numeroCuenta, tipoCuenta, codigoBanco, moneda, fechaInicial, fechaFinal, cantidadRegistros, pagina } = params;
  const URL_PARAMS = `${numeroCuenta}/${tipoCuenta}/${codigoBanco}/${moneda}/${fechaInicial}/${fechaFinal}/${cantidadRegistros}/${pagina}`;
  return axios.get<IResponseData<IDataTable>>(
    `${process.env.CONSULTIVO_API_BASE_URL}extractos/obtenerDatosResultadoExtractos/${URL_PARAMS}`,
  );
};
const getDataTableExtractosMock = () => {
  return new Promise<IResponseData<IDataTable>>(resolve => {
    setTimeout(() => {
      return resolve(mockDataExtractos);
    }, 3000);
  });
};

const getReports = (params: IExtractosReporte) => {
  const { numeroCuenta, tipoCuenta, codigoBanco, moneda, fechaInicial, fechaFinal, nombreBanco, tipoReporte, denominacion } = params;
  const URL_PARAMS = `${tipoReporte}/${numeroCuenta}/${nombreBanco}/${tipoCuenta}/${codigoBanco}/${moneda}/${fechaInicial}/${fechaFinal}`;

  return axios.get<IResponseData<any>>(
    `${process.env.CONSULTIVO_API_BASE_URL}extractos/obtenerExtractoReporte/${URL_PARAMS}?denominacion=${denominacion}`,
    verifyTypeReport(tipoReporte),
  );
};

const getDetailMoviment = async (params: IDataMovimentDetail): Promise<IDataMovimentDetailResponse> => {
  const response = await axios.get(`${process.env.CONSULTIVO_API_BASE_URL}comprobante/`, {
    params,
  });

  if (response.status === 204) {
    return {
      baja: '',
      codigoBcra: '',
      codigoSucursal: '',
      fechaMovimiento: '',
      fechaProcesamiento: '',
      fechaValor: '',
      id: 0,
      importe: 0,
      moneda: '',
      numeroCorrelativo: 0,
      numeroCuenta: '',
      numeroHoja: 0,
      texto: '-',
      tipoCuenta: '',
      tipoMovimiento: '',
    };
  }
  return response.data as IDataMovimentDetailResponse;
};

export { getBancos, getDataTableExtractos, getDataTableExtractosMock, getReports, getDetailMoviment };
