/* eslint-disable react/function-component-definition */
import { DescriptionBankNotEnabled, ImagenBankNotEnabled, TitleBankNotEnabled, WrapperBankNotEnabled } from './style';
import Binoculares from '../../assets/images/Binoculars.png';

const ScreenBankNotEnabled = () => {
  return (
    <WrapperBankNotEnabled>
      <ImagenBankNotEnabled src={Binoculares} />
      <TitleBankNotEnabled>La cuenta no está disponible en este momento</TitleBankNotEnabled>
      <DescriptionBankNotEnabled>Seleccioná otra cuenta para continuar.</DescriptionBankNotEnabled>
    </WrapperBankNotEnabled>
  );
};

export default ScreenBankNotEnabled;
