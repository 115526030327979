/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable spaced-comment */
/* eslint-disable react/self-closing-comp */
import { Flex } from '@chakra-ui/react';
import { useEffect, useState, useContext, useRef } from 'react';
import { useQuery, useQueryClient } from 'react-query';
// eslint-disable-next-line import/no-unresolved
import { pushAnalyticsEvent, useToastOptions } from '@react-ib-mf/style-guide-ui';
import { LoadingScreenComponent as LoadingScreen } from '@wow/loading-screen';
import { TablePaginationComponent } from '@wow/table-pagination';
import imgEmptyState from '../../assets/images/Binoculars.png';
import { IHeader } from '../../interfaces/IExtractos';
import { ITablaExtractos } from '../../interfaces/IResponseTable';
import { getDataTableExtractos } from '../../services/apiExtractos';
import EmptyStates from '../EmptyStates';
import { TextEmptyState } from './Styled';
import TableComponent from './TableComponent';
import { AppContext } from '../../provider/AppContextProvider';
import messages from '../../lang/es.json';
import { formatDate } from '../../utils/tablaExtractos';
import ScreenBankNotEnabled from '../ScreenBankNotEnabled';
import { IPagination } from '../../interfaces/IPagination';

interface IProps {
  data: ITablaExtractos;
  isSearch: boolean;
}

function TablaExtractos({ isSearch, data }: IProps) {
  const bankAccountRef = useRef(data);
  const queryClient = useQueryClient();
  const { input } = data;
  const [detailAccount, setDetailAccount] = useState<IHeader>({
    icon: '',
    title: '',
    tipoCuenta: '',
    numeroCuenta: '',
    denominacion: '',
    fechaInicial: '',
    fechaFinal: '',
    saldoInicial: '',
    saldoFinal: '',
    idBanco: '',
  });
  const [pagination, setPagination] = useState<IPagination>({
    totalFilas: 0,
    pagina: 0,
    cantidadRegistros: 0,
    totalPaginas: 0,
  });
  const [pagina, setPagina] = useState<number>(0);
  const [cantidadRegistros, setCantidadRegistros] = useState<number>(15);
  const [icon, setIcon] = useState('');
  const [, setToastOptions] = useToastOptions();
  const { reportExtractosFetched, loadingReport, setLastDateUpdated, setErrorDataExtractos, setShowDateExtractos } = useContext(
    AppContext,
  ) as any;

  const [bankNotEnabled, setBankNotEnabled] = useState(false);

  if (isSearch) {
    bankAccountRef.current = data;
  }

  const {
    data: dataTable,
    refetch: refetchData,
    isFetching: isDataTableFetching,
    isError: isErrorDataTable,
  } = useQuery(['GetDataTableExtractos'], () =>
    getDataTableExtractos({ ...bankAccountRef.current.input, cantidadRegistros, pagina })
      .then(res => {
        setIcon(`${process.env.URL_BANK_LOGOS}logo${bankAccountRef.current.input.codigoBanco}.png`);
        setLastDateUpdated(res.data.data.datosGeneralesView.fechaDatosActualizados);
        setBankNotEnabled(false);
        const { totalFilas } = res.data.data.datosGeneralesView;
        const totalPaginas = calculateTotalPages(totalFilas, cantidadRegistros);
        setPagination({ totalFilas, pagina, cantidadRegistros, totalPaginas });
        return res.data;
      })
      .catch(err => {
        const codeErrorService = err.response.data.code;
        if (codeErrorService === '0101') {
          setBankNotEnabled(true);
        }
      }),
  );
  useEffect(() => {
    refetchData();
  }, [pagina, cantidadRegistros, queryClient, isSearch]);

  useEffect(() => {
    if (dataTable !== undefined) {
      const { fechaInicial, fechaFinal } = input;
      const importFormat = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      const { denominacion, nombreBanco } = bankAccountRef.current;
      const { saldoFinal, saldoInicial } = dataTable.data.datosGeneralesView;
      const initDate = fechaInicial || '';
      const dateFormatInit = new Date(initDate);
      const endDate = fechaFinal || '';
      const dateFormatEnd = new Date(endDate);
      setDetailAccount({
        icon,
        title: nombreBanco,
        tipoCuenta: input.tipoCuenta ? input.tipoCuenta : '',
        numeroCuenta: input.numeroCuenta ? input.numeroCuenta : '',
        denominacion,
        fechaInicial: formatDate(dateFormatInit),
        fechaFinal: formatDate(dateFormatEnd),
        saldoInicial: importFormat.format(saldoInicial),
        saldoFinal: importFormat.format(saldoFinal),
        idBanco: input.codigoBanco ? input.codigoBanco : '',
      });
    }
  }, [dataTable, input && isSearch]);

  useEffect(() => {
    if (isErrorDataTable) {
      setToastOptions({ description: messages.toast.errorDescription, status: 'error' });
      setErrorDataExtractos(true);
      setShowDateExtractos(false);
    } else {
      setErrorDataExtractos(false);
      setShowDateExtractos(true);
    }
  }, [isErrorDataTable]);

  const calculateTotalPages = (total: number, registros: number): number => {
    let totalPages = 0;
    if (total) {
      totalPages = Math.ceil(total / registros);
    }
    return totalPages;
  };

  function changePage(newPage: number) {
    pushAnalyticsEvent({
      event: 'switch_page',
      selection_type: `${newPage}`,
    });
    setPagina(newPage);
  }

  const { title } = detailAccount;
  const dataDetail = { ...detailAccount, icon, codigoBanco: bankAccountRef.current.input?.codigoBanco };

  if (isErrorDataTable) {
    return null;
  }
  return (
    <>
      {!isDataTableFetching && dataTable?.data.movimientoViews.length === 0 && !isErrorDataTable && (
        <EmptyStates
          image={imgEmptyState}
          title={messages.emptyState.title}
          description={
            <TextEmptyState>
              <p>{messages.emptyState.description}</p>
            </TextEmptyState>
          }
        />
      )}
      {isDataTableFetching && <LoadingScreen title={messages.loadingScreen.title} description={messages.loadingScreen.description} />}
      {!isDataTableFetching && dataTable?.data.movimientoViews.length !== undefined && dataTable?.data.movimientoViews.length > 0 && (
        <>
          {reportExtractosFetched && loadingReport && (
            <LoadingScreen title={messages.loadingScreen.title} description={messages.loadingScreen.description} />
          )}
          <Flex w='full' mt={0} flexDirection='row' />
          <TableComponent
            detailAccount={dataDetail}
            movimientos={dataTable?.data.movimientoViews}
            saldoPorDiasView={dataTable?.data.saldoPorDiasView}
            input={bankAccountRef.current.input}
            nombreBanco={title}
            page={pagina}
          />
          <TablePaginationComponent
            totalPages={pagination.totalPaginas || 1}
            currentIndex={pagination.pagina}
            totalRecords={pagination.totalFilas}
            perPage={[15, 30, 45]}
            disabledPerPage={pagination.totalFilas <= 15}
            currentPerPage={pagination.cantidadRegistros}
            onNext={() => changePage(pagina + 1)}
            onPrev={() => changePage(pagina - 1)}
            onFirst={() => changePage(0)}
            onLast={() => changePage(pagination.totalPaginas - 1)}
            onJump={(value: number) => {
              changePage(Number(value) - 1);
            }}
            onPerPageChange={(value: number) => {
              setCantidadRegistros(Number(value));
              changePage(0);
            }}
          />
        </>
      )}
      {bankNotEnabled && <ScreenBankNotEnabled />}
    </>
  );
}

export default TablaExtractos;
