import styled from 'styled-components';

const DateLabel = styled.div`
  display: flex;
  float: right;
  margin-top: -22px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--chakra-colors-grey-100);
  letter-spacing: 0.5px;

  span {
    color: var(--chakra-colors-grey-120);
    font-weight: 500;
    padding-left: 4px;
  }
`;

export default DateLabel;
