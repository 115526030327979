// eslint-disable-next-line consistent-return
const verifyTypeReport = (tipoReporte: any): any => {
  if (tipoReporte === 'XLSX') {
    return {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://qaweb.interbanking.com.ar',
      },
    };
  }
  if (tipoReporte === 'CSV') {
    return {
      responseType: 'blob',
      headers: {
        'Content-Type': 'text/csv; charset=UTF-8',
        'Access-Control-Allow-Origin': 'https://qaweb.interbanking.com.ar',
      },
    };
  }
  if (tipoReporte === 'TXT') {
    return {
      responseType: 'text',
      headers: {
        'Content-Type': 'text/plain',
        'Access-Control-Allow-Origin': 'https://qaweb.interbanking.com.ar',
      },
    };
  }
  if (tipoReporte === 'PDF') {
    return {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/pdf',
        'Access-Control-Allow-Origin': 'https://qaweb.interbanking.com.ar',
      },
    };
  }
};

export default verifyTypeReport;
