import styled from 'styled-components';
import { themeChakra } from '@wow/themes';
import {
  BACKGROUND_BTN_REPORTS_NOT_OPEN,
  BACKGROUND_BTN_REPORTS_OPEN,
  BACKGROUND_DROPDOW_OPTION_REPORTS,
  BACKGROUND_DROPDOW_REPORTS,
  BORDER_COLOR_BTN_REPORTS,
  COLOR_DROPDOW_OPTION_REPORTS,
  HOVER_BACKGROUND_DROPDOW_OPTION_REPORTS,
  TITLE_COLOR_BTN_REPORTS,
} from '../../../designTokens/colors';
import { IReportsButton } from '../../../interfaces/IReportsComponents';

const {
  textStyles: { labelLg, labelLgBold },
} = themeChakra;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

const ReportsButton = styled.div<IReportsButton>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 40px;
  width: 180px;
  background: ${props => (props.openReports ? BACKGROUND_BTN_REPORTS_OPEN : BACKGROUND_BTN_REPORTS_NOT_OPEN)};
  border: 1px solid ${BORDER_COLOR_BTN_REPORTS};
  border-radius: 8px;
  flex: none;
  flex-grow: 0;
  padding: 0px 12px 0px 16px;
  cursor: pointer;
`;

const ReportsTitle = styled.div`
  color: ${TITLE_COLOR_BTN_REPORTS};
  font-family: ${labelLgBold.fontFamily};
  font-weight: ${labelLgBold.fontWeight};
  font-size: ${labelLgBold.fontSize};
  line-height: ${labelLgBold.lineHeight};
  text-align: center;
  letter-spacing: ${labelLgBold.letterSpacing};
`;

const ReportsDropdow = styled.div`
  padding: 8px 12px 12px;
  left: 122px;
  top: 350px;
  position: absolute;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 244px;
  height: 212px;
  background: ${BACKGROUND_DROPDOW_REPORTS};
  box-shadow:
    0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);
`;

const OptionDropdown = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 199px;
  padding: 0px 4px 0px 12px;
  width: 220px;
  height: 48px;
  background: ${BACKGROUND_DROPDOW_OPTION_REPORTS};
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  font-family: ${labelLg.fontFamily};
  font-weight: ${labelLg.fontWeight};
  font-size: ${labelLg.fontSize};
  line-height: ${labelLg.lineHeight};
  letter-spacing: ${labelLg.letterSpacing};
  color: ${COLOR_DROPDOW_OPTION_REPORTS};
  cursor: pointer;

  :hover {
    background: ${HOVER_BACKGROUND_DROPDOW_OPTION_REPORTS};
  }
`;

export { Columns, OptionDropdown, ReportsButton, ReportsDropdow, ReportsTitle, Row };
