import styled from 'styled-components';
import { themeChakra } from '@wow/themes';
import {
  BACKGROUND_TBODY_TD_BRANCHCODE_TABLA_EXTRACTOS,
  BACKGROUND_THEAD_TH_TABLA_EXTRACTOS,
  BACKGROUND_TOOLTIP_TABLA_EXTRACTOS,
  COLOR_SPAN_TABLA_EXTRACTOS,
  COLOR_TBODY_TD_BRANCHCODE_TABLA_EXTRACTOS,
  COLOR_TBODY_TD_NEGATIVE_TABLA_EXTRACTOS,
  COLOR_TBODY_TD_POSITIVE_TABLA_EXTRACTOS,
  COLOR_TBODY_TD_TABLA_EXTRACTOS,
  COLOR_TOOLTIP_TABLA_EXTRACTOS,
} from '../../../designTokens/colors';
import TableProps from './interfaces';

const {
  textStyles: { titleSmBold, bodyMd, bodySmSemi, bodySm },
} = themeChakra;

const TableContainer = styled.div<Pick<TableProps, 'isLargerThan1536'>>`
  overflow-x: ${({ isLargerThan1536 }) => (isLargerThan1536 ? 'auto' : 'scroll')};
  width: 100%;
  max-width: 100%;
`;

const Table = styled.table.withConfig({
  shouldForwardProp: prop => !['isLargerThan1536'].includes(prop),
})<TableProps>`
  margin: 0;
  margin-top: 10px;
  border-collapse: collapse;
  font-size: 0.85vw;
  width: 100%;
  max-width: ${({ isLargerThan1536 }) => (isLargerThan1536 ? '1536px' : '100%')};
  overflow-x: hidden;
  font-family: ${titleSmBold.fontFamily};
  z-index: 0;

  tr {
    height: 55px;
  }

  thead th {
    background: ${BACKGROUND_THEAD_TH_TABLA_EXTRACTOS};
    padding-left: 12px;
    &:first-child {
      border-radius: 8px 0px 0px 8px;
    }
    &:nth-child(3) {
      width: 200px;
    }
    &:last-child {
      border-radius: 0px 8px 8px 0px;
    }
    span {
      text-align: center;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      font-family: ${titleSmBold.fontFamily};
      font-weight: ${titleSmBold.fontWeight};
      font-size: ${titleSmBold.fontSize};
      line-height: ${titleSmBold.lineHeight};
      letter-spacing: ${titleSmBold.letterSpacing};
      display: flex;
      align-items: center;
      color: ${COLOR_SPAN_TABLA_EXTRACTOS};
    }
  }

  tbody td {
    padding: 0;
    text-align: center;
    height: 30px;
    font-family: ${bodyMd.fontFamily};
    font-size: ${bodyMd.fontSize};
    font-weight: ${bodyMd.fontWeight};
    line-height: ${bodyMd.lineHeight};
    letter-spacing: ${bodyMd.letterSpacing};
    color: ${COLOR_TBODY_TD_TABLA_EXTRACTOS};
    padding-left: 12px;

    &:nth-child(3) {
      text-align: right;
    }
    .positive {
      color: ${COLOR_TBODY_TD_POSITIVE_TABLA_EXTRACTOS};
    }
    .negative {
      color: ${COLOR_TBODY_TD_NEGATIVE_TABLA_EXTRACTOS};
    }
    .branchCode {
      background: ${BACKGROUND_TBODY_TD_BRANCHCODE_TABLA_EXTRACTOS};
      padding: 2px 4px;
      border-radius: 4px;
      width: 33px;
      color: ${COLOR_TBODY_TD_BRANCHCODE_TABLA_EXTRACTOS};
      font-weight: ${bodySmSemi.fontWeight};
    }
  }
`;

const HeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const customToolTip = {
  borderRadius: '8px',
  padding: '8px 10px',
  color: COLOR_TOOLTIP_TABLA_EXTRACTOS,
  fontFamily: bodySm.fontFamily,
  fontWeight: bodySm.fontWeight,
  fontSize: bodySm.fontSize,
  lineHeight: bodySm.lineHeight,
  letterSpacing: bodySm.letterSpacing,
  alignItems: 'center',
  textAlign: 'center',
  backgroundColor: BACKGROUND_TOOLTIP_TABLA_EXTRACTOS,
};

const ColumnCheckBox = styled.div`
  width: 384px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 12px;
`;

interface ISeparator {
  height?: string;
  width?: string;
}

const Separator = styled.div<ISeparator>`
  height: ${props => (props.height ? props.height : '1px')};
  width: ${props => (props.width ? props.width : '30px')};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

export { ColumnCheckBox, customToolTip, HeaderColumn, Separator, Table, TableContainer, Row };
