import styled from 'styled-components';
import { themeChakra } from '@wow/themes';

const {
  textStyles: { bodyLg, titleMdBold },
} = themeChakra;

const TextEmptyState = styled.div`
  text-align: center;
  p {
    font-weight: ${bodyLg.fontWeight};
    margin: 0 0 24px 0;
    span {
      display: block;
      font-weight: ${titleMdBold.fontWeight};
      font-size: ${titleMdBold.fontSize};
    }
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

export { TextEmptyState, Row };
