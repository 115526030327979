/* eslint-disable no-restricted-globals */
// eslint-disable-next-line import/no-unresolved
import { Button } from '@chakra-ui/react';
import styled from 'styled-components';
import { themeChakra } from '@wow/themes';
import { COLOR_TITLE_SCREEN_ERROR } from '../../designTokens/colors';
import { IScreenError } from '../../interfaces/IScreenError';
import messages from '../../lang/es.json';

const {
  textStyles: { bodyLg, h4Bold },
} = themeChakra;

function ScreenError(props: IScreenError) {
  const { title, description, image } = props;
  function reloadPage() {
    location.reload();
  }
  return (
    <Container>
      <div className='image'>
        <img data-testid='screen-Err-img' src={image} alt={messages.screenErrorText.alt} />
      </div>
      <div className='title'>
        <p data-testid='screen-Err-title'>{title}</p>
      </div>
      <div className='description' data-testid='screen-Err-desc'>
        {description}
      </div>
      <div style={{ marginTop: '32px' }}>
        <Button onClick={() => reloadPage()} size='sm'>
          {messages.screenErrorText.textButton}
        </Button>
      </div>
    </Container>
  );
}

export default ScreenError;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;

  .image {
    margin-bottom: 28px;
  }
  .title {
    font-family: ${h4Bold.fontFamily};
    font-weight: ${h4Bold.fontWeight};
    font-size: ${h4Bold.fontSize};
    color: ${COLOR_TITLE_SCREEN_ERROR};
    margin-bottom: 8px;
    p {
      margin: 0;
    }
  }
  .description {
    font-family: ${bodyLg.fontFamily};
    font-weight: ${bodyLg.fontWeight};
    font-size: ${bodyLg.fontSize};
    color: ${COLOR_TITLE_SCREEN_ERROR};
  }
`;
