import { Box, Button } from '@chakra-ui/react';

// eslint-disable-next-line import/no-unresolved
import { CustomSelect, useToastOptions, pushAnalyticsEvent } from '@react-ib-mf/style-guide-ui';
import { useEffect, useState } from 'react';
import { FiChevronLeft, FiChevronRight, FiSliders } from 'react-icons/fi';
import { useQuery } from 'react-query';
import dayjs, { Dayjs } from 'dayjs';
import { Banco, IOption, TipoCuenta, TipoMoneda } from '../../interfaces/IPropsCustomSelect';
import { ITablaExtractos } from '../../interfaces/IResponseTable';
import { getBancos } from '../../services/apiExtractos';
import CustomButton from '../CustomButton';
import { ScrollButon, ScrollContainer, ScrollWrapper } from './style';
import messages from '../../lang/es.json';
import {
  BACKGROUND_SCROLL_BTN_DISABLED_CHIP_EXTRACTOS,
  BORDER_COLOR_BOX_CHIP_EXTRACTOS,
  COLOR_BTN_CUSTOM_BUTTON,
} from '../../designTokens/colors';
import TagDatepicker from './TagDatepicker';

interface IProps {
  onChangeChips: (e: ITablaExtractos) => void;
  onSearch: () => void;
  isFetching: boolean;
}

export default function ChipsExtractos({ onChangeChips, onSearch, isFetching }: IProps) {
  const [objectSelectedBank, setObjectSelectedBank] = useState<Banco | undefined>();

  const [selectedBank, setSelectedBank] = useState<IOption | undefined>();
  const [selectedTypeAccount, setSelectedTypeAccounts] = useState<IOption | undefined>();
  const [selectedCurrency, setSelectedCurrency] = useState<IOption | undefined>();
  const [selectedAccount, setSelectedAccount] = useState<IOption | undefined>();

  const [bankData, setBankData] = useState<IOption[] | []>([]);
  const [typeAccountsData, setTypeAccountsData] = useState<IOption[] | []>([]);
  const [currenciesData, setCurrenciesData] = useState<IOption[] | []>([]);
  const [accountsData, setAccountsData] = useState<IOption[] | []>([]);

  const [isClearDisabled, setIsClearDisabled] = useState(true);
  const [isFetchingDisabled, setIsFetchingDisabled] = useState(true);

  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  const maxDateRange = dayjs().subtract(1, 'day');
  const minDateRange = dayjs().subtract(180, 'day');
  const maxEndDate = startDate ? (startDate.add(60, 'day').isBefore(maxDateRange) ? startDate.add(60, 'day') : maxDateRange) : maxDateRange;

  const selectedInitState = {
    isSelectedBank: false,
    isSelectedTypeAccount: false,
    isSelectedCurrency: false,
    isSelectedAccount: false,
  };

  const [selectedChip, setSelectedChip] = useState(selectedInitState);
  const [selectedChipData, setSelectedChipData] = useState({});
  const [selectedChipDefault, setSelectedChipDefault] = useState({});

  const { data: dataForFilters, isFetching: dataForFiltersFetching } = useQuery(['GetBanks'], () => getBancos().then(res => res.data));

  type typesFilters = Banco[] | TipoCuenta[] | TipoMoneda[];

  const [, setToastOptions] = useToastOptions();

  const clearFilters = () => {
    setSelectedBank(bankData.filter(bank => bank.default)[0]);
    setSelectedTypeAccounts(typeAccountsData.filter(typeAccount => typeAccount.default)[0]);
    setSelectedCurrency(currenciesData.filter(currency => currency.default)[0]);
    setSelectedAccount(accountsData.filter(account => account.default)[0]);
    setSelectedChip(selectedInitState);
    setIsClearDisabled(true);
    setToastOptions({ description: '¡Listo! Borraste los filtros con éxito.', status: 'success' });
    pushAnalitycsRemove('CTA Limpiar');
    setDisableRight(false);
    setStartDate(maxDateRange);
    setEndDate(maxDateRange);
  };

  /**
   * Función que retorna un array de objetos de tipo IOpotion[] que será utilizado para el componente CustomSelect
   * @param data Array de objetos de tipo typesFilters
   * @param value Nombre de la key (Debe existir en el @param data) que tiene el valor que será usado como value en el objeto de tipo IOption
   * @param label Nombre de la key (Debe existir en el @param data) que tiene el valor que será usado como label en el objeto de tipo IOption
   * @returns IOption[]
   */
  const createObjectFilter = (data: typesFilters, value: string, label: string) => {
    const dataFilter = data.map(item => {
      const dataTemp: IOption = {
        value: Object.entries(item).filter(x => x.includes(value))[0][1],
        label: Object.entries(item).filter(x => x.includes(label))[0][1],
      };

      return dataTemp;
    });
    return dataFilter;
  };

  // Se crea el objeto para el filtro de bancos y se setea el banco por defecto
  useEffect(() => {
    if (!dataForFiltersFetching && dataForFilters !== undefined) {
      const bankList = dataForFilters?.data.bancos;
      const filterBanks = bankList !== undefined ? createObjectFilter(bankList, 'idBanco', 'nombreBanco') : [];
      if (filterBanks.length > 0) filterBanks[0].default = true;
      const selectedOption = filterBanks.filter(bank => bank.default)[0];
      setSelectedBank(selectedOption);
      setBankData(filterBanks);
    } else {
      setBankData([]);
    }
  }, [dataForFilters, dataForFiltersFetching]);

  // Creamos un objeto del banco seleccionado y lo guardamos en un estado
  useEffect(() => {
    if (selectedBank !== undefined) {
      const currentBank = dataForFilters?.data.bancos.filter(banco => banco.idBanco === selectedBank.value)[0];
      setObjectSelectedBank(currentBank);
    }
  }, [selectedBank]);

  // Se crea el objeto para el filtro tipo de cuentas y se setea el tipo de cuenta corriente por defecto
  useEffect(() => {
    if (objectSelectedBank !== undefined) {
      const typeAccountsList = objectSelectedBank?.tipoCuentas;
      const filterTypeAccounts =
        typeAccountsList !== undefined ? createObjectFilter(typeAccountsList, 'codigoTipoCuenta', 'nombreTipoCuenta') : [];
      if (filterTypeAccounts.length > 0) filterTypeAccounts[0].default = true;
      const selectedOption = filterTypeAccounts.filter(typeAccount => typeAccount.default)[0];
      setSelectedTypeAccounts(selectedOption);
      setTypeAccountsData(filterTypeAccounts);
    }
  }, [objectSelectedBank]);

  // Se crea el objeto para el filtro moneda y se setea la moneda ARS por defecto
  useEffect(() => {
    if (selectedTypeAccount !== undefined) {
      const accountsByTypeAccount = objectSelectedBank?.tipoCuentas.filter(
        typeAccount => typeAccount.codigoTipoCuenta === selectedTypeAccount.value,
      )[0];

      const filterTypesCurrencies =
        accountsByTypeAccount !== undefined ? createObjectFilter(accountsByTypeAccount.tipoMonedas, 'moneda', 'moneda') : [];
      filterTypesCurrencies[0].default = true;

      const selectedCurrencyTemp = filterTypesCurrencies.filter(currency => currency.default)[0];
      setSelectedCurrency(selectedCurrencyTemp);
      setCurrenciesData(filterTypesCurrencies);
    }
  }, [selectedTypeAccount]);

  // Se crea el objeto para el filtro cuenta y se setea la cuenta por defecto
  useEffect(() => {
    if (selectedCurrency !== undefined) {
      const accountsByTypeAccount = objectSelectedBank?.tipoCuentas.filter(
        typeAccount => typeAccount.codigoTipoCuenta === selectedTypeAccount?.value,
      )[0];
      const accountByCurrency = accountsByTypeAccount?.tipoMonedas.filter(currency => currency.moneda === selectedCurrency.value)[0];
      const filterAccounts = accountByCurrency?.cuentas?.map(account => {
        const { cbu, denominacion, numeroCuenta } = account;
        const option: IOption = {
          value: numeroCuenta,
          description: denominacion,
          label: cbu,
          customLabel: true,
        };
        return option;
      });
      if (filterAccounts !== undefined) {
        filterAccounts[0].default = true;
        const selectedOption = filterAccounts?.filter(account => account.default)[0];
        setSelectedAccount(selectedOption);
        setAccountsData(filterAccounts);
      }
    }
  }, [selectedCurrency]);

  // Se setea el periodo en Desde y Hasta del día anterior al actual
  useEffect(() => {
    if (selectedAccount !== undefined) {
      setStartDate(maxDateRange);
      setEndDate(maxDateRange);
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (startDate && endDate) {
      if (endDate.isAfter(maxEndDate)) {
        setEndDate(maxEndDate);
      }

      if (endDate.isBefore(startDate)) {
        setEndDate(startDate);
      }

      const formattedDates = {
        startDate: startDate ? startDate.toISOString().slice(0, 10) : '',
        endDate: endDate ? endDate.toISOString().slice(0, 10) : '',
      };

      const inputData = {
        codigoBanco: selectedBank ? selectedBank.value : '',
        tipoCuenta: selectedTypeAccount ? selectedTypeAccount.value : '',
        moneda: selectedCurrency ? selectedCurrency.value : '',
        numeroCuenta: selectedAccount ? selectedAccount.value : '',
        fechaInicial: formattedDates.startDate,
        fechaFinal: formattedDates.endDate,
      };
      setSelectedChipData(inputData);
      const defaultState = JSON.stringify(selectedChipDefault);
      const newState = JSON.stringify(inputData);

      if (defaultState !== newState && Object.values(selectedChipDefault).length > 0) {
        setIsFetchingDisabled(false);
        setIsClearDisabled(false);
      } else {
        setIsFetchingDisabled(true);
        setIsClearDisabled(true);
      }
      onChangeChips({
        input: inputData,
        nombreBanco: selectedBank ? selectedBank.label : '',
        denominacion: selectedAccount?.description ? selectedAccount.description : '',
      });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    const isAnyFilterSelected =
      selectedBank?.value !== bankData[0]?.value ||
      selectedTypeAccount?.value !== typeAccountsData[0]?.value ||
      selectedCurrency?.value !== currenciesData[0]?.value ||
      selectedAccount?.value !== accountsData[0]?.value ||
      (startDate &&
        endDate &&
        (startDate.format('YYYY-MM-DD') !== maxDateRange.format('YYYY-MM-DD') ||
          endDate.format('YYYY-MM-DD') !== maxDateRange.format('YYYY-MM-DD')));

    setIsClearDisabled(!isAnyFilterSelected);
  }, [
    selectedBank,
    selectedTypeAccount,
    selectedCurrency,
    selectedAccount,
    startDate,
    endDate,
    bankData,
    typeAccountsData,
    currenciesData,
    accountsData,
    maxDateRange,
  ]);

  useEffect(() => {
    if (!isFetching) {
      setSelectedChipDefault(selectedChipData);
      setIsFetchingDisabled(true);
    }
  }, [isFetching]);

  function pushAnalitycsFilter(aplied: string) {
    pushAnalyticsEvent({
      event: 'filter_listing',
      filter_type: aplied,
    });
  }

  function pushAnalitycsRemove(remove: string) {
    pushAnalyticsEvent({
      event: 'remove_filters',
      selection: remove,
    });
  }

  const [scrollLeft, setScrollLeft] = useState(0);
  const [disableRight, setDisableRight] = useState(false);

  const sliderLeft = () => {
    const slider = document.getElementById('slider');
    const firstMove = 86;
    if (slider) {
      const anchoTotal = slider.scrollWidth;
      const anchoVisible = slider.clientWidth;
      let scrollActual = slider.scrollLeft;
      scrollActual -= firstMove;
      const dif = anchoTotal - anchoVisible;
      if (scrollActual <= dif) {
        setDisableRight(false);
      }
      if (scrollActual === dif) {
        slider.scrollLeft -= 100;
        setScrollLeft(slider.scrollLeft);
      } else {
        slider.scrollLeft -= firstMove;
        setScrollLeft(slider.scrollLeft);
      }
    }
  };

  const sliderRight = () => {
    const slider = document.getElementById('slider');
    const firstMove = 86;
    if (slider) {
      const anchoVisible = slider?.clientWidth;
      let scrollActual = slider?.scrollLeft;
      scrollActual += firstMove;
      const anchoTotal = slider?.scrollWidth;
      const dif = anchoTotal - anchoVisible;
      if (scrollActual >= dif) {
        setDisableRight(true);
      }
      slider.scrollLeft += 100;
      setScrollLeft(slider.scrollLeft);
    }
  };

  return (
    <Box
      display='flex'
      flexDirection='row'
      justifyContent='space-between'
      w='auto'
      marginTop='24px'
      backgroundColor='transparent'
      alignContent='center'
      alignItems='center'
      borderRadius='8px'
      borderStyle='solid'
      borderWidth='1px'
      borderColor={BORDER_COLOR_BOX_CHIP_EXTRACTOS}
      height='64px'
    >
      <Box display='flex' flexDirection='row' gap='8px' paddingLeft='10px' justifyContent='start' alignItems='center'>
        <Button
          onClick={() => console.log('Usar funcion correspondiente')}
          leftIcon={<FiSliders />}
          isDisabled
          size='sm'
          variant='secondary-text'
        >
          {messages.chipsExtracts.textButton}
        </Button>
        <div style={{ display: 'flex' }}>
          <ScrollButon
            data-testid={messages.chipsExtracts.scrollLeft}
            disabled={scrollLeft === 0}
            style={{
              paddingRight: '2px',
              border: scrollLeft === 0 ? `1px solid ${BACKGROUND_SCROLL_BTN_DISABLED_CHIP_EXTRACTOS}` : '',
            }}
            position='left'
            onClick={() => sliderLeft()}
          >
            <FiChevronLeft
              style={{ paddingRight: '5px' }}
              size={'24px'}
              color={scrollLeft === 0 ? BACKGROUND_SCROLL_BTN_DISABLED_CHIP_EXTRACTOS : ''}
            />
          </ScrollButon>
          <ScrollWrapper>
            <ScrollContainer id='slider' style={{ overflowX: 'hidden', overflowY: 'hidden' }}>
              <CustomSelect
                name={messages.customSelect.nameBankId}
                data-testid={messages.customSelect.nameBankId}
                options={bankData}
                onSelectOption={e => {
                  setSelectedBank(e);
                  setSelectedChip({ ...selectedChip, isSelectedBank: !e.default });
                  pushAnalitycsFilter('Aplicado-Banco');
                }}
                value={selectedBank}
                label={messages.customSelect.labelBank}
                onClearFilter={() => {
                  setSelectedBank(bankData.filter(bank => bank.default)[0]);
                  setSelectedChip({ ...selectedChip, isSelectedBank: false });
                  pushAnalitycsRemove('Banco');
                }}
                padding='0 0 0 2px'
              />
              <CustomSelect
                name={messages.customSelect.nameAccountTypeId}
                data-testid={messages.customSelect.nameAccountTypeId}
                options={typeAccountsData}
                padding='0 0 0 2px'
                onSelectOption={e => {
                  setSelectedTypeAccounts(e);
                  setSelectedChip({ ...selectedChip, isSelectedTypeAccount: !e.default });
                  pushAnalitycsFilter('Aplicado-TipoCuenta');
                }}
                value={selectedTypeAccount}
                label={messages.customSelect.labelAccountType}
                onClearFilter={() => {
                  setSelectedTypeAccounts(typeAccountsData.filter(typeAccount => typeAccount.default)[0]);
                  setSelectedChip({ ...selectedChip, isSelectedTypeAccount: false });
                  pushAnalitycsRemove('TipoCuenta');
                }}
              />
              <CustomSelect
                name={messages.customSelect.nameCurrencyId}
                data-testid={messages.customSelect.nameCurrencyId}
                options={currenciesData}
                padding='0 0 0 2px'
                onSelectOption={e => {
                  setSelectedCurrency(e);
                  setSelectedChip({ ...selectedChip, isSelectedCurrency: !e.default });
                  pushAnalitycsFilter('Aplicado-Moneda');
                }}
                value={selectedCurrency}
                label={messages.customSelect.labelCurrency}
                onClearFilter={() => {
                  setSelectedCurrency(currenciesData.filter(currency => currency.default)[0]);
                  setSelectedChip({ ...selectedChip, isSelectedCurrency: false });
                  pushAnalitycsRemove('Moneda');
                }}
              />
              <CustomSelect
                name={messages.customSelect.nameAccount}
                data-testid={messages.customSelect.nameAccount}
                options={accountsData}
                padding='0 0 0 2px'
                onSelectOption={e => {
                  setSelectedAccount(e);
                  setSelectedChip({ ...selectedChip, isSelectedAccount: !e.default });
                  pushAnalitycsFilter('Aplicado-Cuenta');
                }}
                value={selectedAccount}
                label={messages.customSelect.labelAccount}
                onClearFilter={() => {
                  setSelectedAccount(accountsData.filter(currency => currency.default)[0]);
                  setSelectedChip({ ...selectedChip, isSelectedAccount: false });
                  pushAnalitycsRemove('Cuenta');
                }}
                ellipsis={15}
                tooltipText={
                  <div style={{ flexDirection: 'row', textAlign: 'start' }}>
                    <div>CBU: {selectedAccount?.label}</div>
                    <div>Denominación: {selectedAccount?.description}</div>
                  </div>
                }
              />
              <TagDatepicker
                label='Desde'
                value={startDate}
                setValue={setStartDate}
                minDate={minDateRange}
                maxDate={maxDateRange}
                minDateRange={minDateRange}
                maxDateRange={maxDateRange}
                startDate={startDate}
                maxEndDate={maxEndDate}
              />
              <TagDatepicker
                label='Hasta'
                value={endDate}
                setValue={setEndDate}
                disabled={!startDate}
                minDate={startDate || minDateRange}
                maxDate={maxEndDate}
                minDateRange={minDateRange}
                maxDateRange={maxDateRange}
                startDate={startDate}
                maxEndDate={maxEndDate}
              />
            </ScrollContainer>
          </ScrollWrapper>
          <ScrollButon
            disabled={disableRight}
            data-testid={messages.chipsExtracts.scrollRight}
            style={{ paddingLeft: '2px', border: disableRight ? `1px solid ${BACKGROUND_SCROLL_BTN_DISABLED_CHIP_EXTRACTOS}` : '' }}
            position='rigth'
            onClick={() => sliderRight()}
          >
            <FiChevronRight
              style={{ paddingLeft: '5px' }}
              size={'24px'}
              color={disableRight ? BACKGROUND_SCROLL_BTN_DISABLED_CHIP_EXTRACTOS : ''}
            />
          </ScrollButon>
        </div>
      </Box>
      <Box display='flex' flexDirection='row' gap='16px' alignItems='center' paddingRight='10px'>
        <CustomButton text={messages.chipsExtracts.customButton} onClick={() => onSearch()} isDisable={isFetchingDisabled} />
        <Button
          data-testid='btn-clearFilters'
          onClick={() => clearFilters()}
          isDisabled={isClearDisabled}
          size='sm'
          variant='secondary-text'
          color={isClearDisabled ? '' : COLOR_BTN_CUSTOM_BUTTON}
        >
          {messages.chipsExtracts.textButtonDelete}
        </Button>
      </Box>
    </Box>
  );
}
