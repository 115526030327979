// eslint-disable-next-line import/no-unresolved
import { IShowColumnsTable, ShowColumnsTable } from '@react-ib-mf/style-guide-ui';
import { FiSettings } from 'react-icons/fi';
import { COLOR_FISETTINGS_CONFIG_COLUMS } from '../../../../designTokens/colors';

function ConfigColumnsComponent(props: IShowColumnsTable) {
  const {
    validateColumnsApplied,
    applyChange,
    resetDefaultColumns,
    validateIsDefaultColumns,
    columnsDefaultTable,
    getAllLeafColumns,
    setShowPersonalizar,
    showPersonalizar,
  } = props;

  return (
    <ShowColumnsTable
      icon={<FiSettings width='20px' height='20px' color={COLOR_FISETTINGS_CONFIG_COLUMS} />}
      getAllLeafColumns={getAllLeafColumns}
      columnsDefaultTable={columnsDefaultTable}
      showPersonalizar={showPersonalizar}
      setShowPersonalizar={() => setShowPersonalizar(!showPersonalizar)}
      validateIsDefaultColumns={validateIsDefaultColumns}
      resetDefaultColumns={resetDefaultColumns}
      validateColumnsApplied={() => validateColumnsApplied()}
      applyChange={() => applyChange()}
    />
  );
}

export default ConfigColumnsComponent;
