import { Tooltip } from '@chakra-ui/react';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

function CustomDay(labelFilter: string, minDateRange: Dayjs, maxDateRange: Dayjs, startDate: Dayjs | null, maxEndDate: Dayjs) {
  return function (props: PickersDayProps<Dayjs>) {
    const { day, outsideCurrentMonth, selected, today, onDaySelect, isFirstVisibleCell, isLastVisibleCell } = props;

    const isLabelHasta = labelFilter === 'Hasta';

    const dayDateFormatted = day.toISOString().slice(0, 10);
    const startDateFormatted = startDate?.toISOString().slice(0, 10);
    const areDatesEqual = dayDateFormatted === startDateFormatted;

    const isDisabledDay = isLabelHasta
      ? day.isBefore(minDateRange) || day.isAfter(maxEndDate) || (day.isBefore(startDate) && !areDatesEqual)
      : day.isBefore(minDateRange) || day.isAfter(maxDateRange);

    const showTooltip = isDisabledDay && isLabelHasta;
    return (
      <Tooltip label={showTooltip ? 'Podés seleccionar\nhasta un máximo de 60\ndías' : ''} hasArrow>
        <span>
          <PickersDay
            day={day}
            outsideCurrentMonth={outsideCurrentMonth}
            selected={selected}
            today={today}
            disabled={isDisabledDay}
            onDaySelect={onDaySelect}
            isFirstVisibleCell={isFirstVisibleCell}
            isLastVisibleCell={isLastVisibleCell}
          />
        </span>
      </Tooltip>
    );
  };
}

export default CustomDay;
