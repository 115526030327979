export const setColumnMemoFiltered = (name: string, value: string) => {
  document.cookie = `${name}=${value}`;
};

export const getColumnMemoFiltered = (name: string, initialValue = '') => {
  return (
    document.cookie.split('; ').reduce((r, v) => {
      const parts = v.split('=');
      return parts[0] === name ? decodeURIComponent(parts[1]) : r;
    }, '') || initialValue
  );
};
