import { Icon } from '@chakra-ui/react';
import { ZoomIn } from 'react-feather';
import { themeChakra } from '@wow/themes';
import { IResponseData } from '../../../src/interfaces/IResponseData';
import { IDataTable } from '../../../src/interfaces/IResponseTable';

const {
  colors: { primary },
} = themeChakra;

const responseTable: IResponseData<IDataTable> = {
  data: {
    datosGeneralesView: {
      fechaDatosActualizados: '11/04/2023',
      saldoFinal: 1168075.58,
      saldoInicial: 0,
      totalFilas: 15,
    },
    saldoPorDiasView: [
      {
        fecha: '2022-11-21T00:00:00',
        saldo: 8557.2,
      },
      {
        fecha: '2022-11-21T00:00:00',
        saldo: 8557.2,
      },
      {
        fecha: '2022-11-21T00:00:00',
        saldo: 8557.2,
      },
    ],
    movimientoViews: [
      {
        codigoComprobante: '3482376',
        codigoDescripcion: 'MOVIMIENTO TIPO CREDITO',
        codigoOpBanco: '14834',
        codigoOperacion: 'COMIS.PYME',
        codigoSucursal: '00000',
        cuit: '9999999999',
        fechaMovimiento: '2022-11-21T00:00:00',
        fechaCorte: '2022-11-21T00:00:00',
        importe: 8557.2,
        codigoOperacionIb: '',
        denominacion: '',
        isSaldoParcial: true,
        verDetalle: <Icon as={ZoomIn} color={primary[500]} />,
        numeroCorrelativo: '123',
        tipoMovimiento: 'C',
      },
      {
        codigoComprobante: '3635804',
        codigoDescripcion: 'MOVIMIENTO TIPO CREDITO',
        codigoOpBanco: '36249',
        codigoOperacion: 'CJA.SEGUR',
        codigoSucursal: '00000',
        cuit: '9999999999',
        fechaMovimiento: '2022-11-21T00:00:00',
        fechaCorte: '2022-11-21T00:00:00',
        importe: 8832.88,
        codigoOperacionIb: '',
        denominacion: '',
        isSaldoParcial: true,
        verDetalle: <Icon as={ZoomIn} color={primary[500]} />,
        numeroCorrelativo: '123',
        tipoMovimiento: 'C',
      },
      {
        codigoComprobante: '3358708',
        codigoDescripcion: 'MOVIMIENTO TIPO CREDITO',
        codigoOpBanco: '76741',
        codigoOperacion: 'CO.CON.SAL',
        codigoSucursal: '00000',
        cuit: '9999999999',
        fechaMovimiento: '2022-11-21T00:00:00',
        fechaCorte: '2022-11-21T00:00:00',
        importe: 13467.14,
        codigoOperacionIb: '',
        denominacion: '',
        isSaldoParcial: false,
        verDetalle: <Icon as={ZoomIn} color={primary[500]} />,
        numeroCorrelativo: '123',
        tipoMovimiento: 'C',
      },
      {
        codigoComprobante: '1509040',
        codigoDescripcion: 'MOVIMIENTO TIPO CREDITO',
        codigoOpBanco: '85071',
        codigoOperacion: 'COM.RECAUD',
        codigoSucursal: '00000',
        cuit: '9999999999',
        fechaMovimiento: '2022-11-21T00:00:00',
        fechaCorte: '2022-11-21T00:00:00',
        importe: 21630.37,
        codigoOperacionIb: '',
        denominacion: '',
        isSaldoParcial: false,
        verDetalle: <Icon as={ZoomIn} color={primary[500]} />,
        numeroCorrelativo: '123',
        tipoMovimiento: 'C',
      },
      {
        codigoComprobante: '7676528',
        codigoDescripcion: 'MOVIMIENTO TIPO CREDITO',
        codigoOpBanco: '54903',
        codigoOperacion: 'D.AU.S.VID',
        codigoSucursal: '00000',
        cuit: '9999999999',
        fechaMovimiento: '2022-11-21T00:00:00',
        fechaCorte: '2022-11-21T00:00:00',
        importe: 25285.45,
        codigoOperacionIb: '',
        denominacion: '',
        isSaldoParcial: false,
        verDetalle: <Icon as={ZoomIn} color={primary[500]} />,
        numeroCorrelativo: '123',
        tipoMovimiento: 'C',
      },
      {
        codigoComprobante: '7374474',
        codigoDescripcion: 'MOVIMIENTO TIPO CREDITO',
        codigoOpBanco: '54296',
        codigoOperacion: 'C.TRAV.CHE',
        codigoSucursal: '00000',
        cuit: '9999999999',
        fechaMovimiento: '2022-11-21T00:00:00',
        fechaCorte: '2022-11-21T00:00:00',
        importe: 26681.37,
        codigoOperacionIb: '',
        denominacion: '',
        isSaldoParcial: false,
        verDetalle: <Icon as={ZoomIn} color={primary[500]} />,
        numeroCorrelativo: '123',
        tipoMovimiento: 'C',
      },
      {
        codigoComprobante: '6433973',
        codigoDescripcion: 'MOVIMIENTO TIPO CREDITO',
        codigoOpBanco: '71755',
        codigoOperacion: 'DEV IB RE',
        codigoSucursal: '00000',
        cuit: '9999999999',
        fechaMovimiento: '2022-11-21T00:00:00',
        fechaCorte: '2022-11-21T00:00:00',
        importe: 69376.27,
        codigoOperacionIb: '',
        denominacion: '',
        isSaldoParcial: false,
        verDetalle: <Icon as={ZoomIn} color={primary[500]} />,
        numeroCorrelativo: '123',
        tipoMovimiento: 'C',
      },
      {
        codigoComprobante: '2227198',
        codigoDescripcion: 'MOVIMIENTO TIPO CREDITO',
        codigoOpBanco: '95336',
        codigoOperacion: 'COBRO SERV',
        codigoSucursal: '00000',
        cuit: '9999999999',
        fechaMovimiento: '2022-11-21T00:00:00',
        fechaCorte: '2022-11-21T00:00:00',
        importe: 95383,
        codigoOperacionIb: '',
        denominacion: '',
        isSaldoParcial: false,
        verDetalle: <Icon as={ZoomIn} color={primary[500]} />,
        numeroCorrelativo: '123',
        tipoMovimiento: 'C',
      },
      {
        codigoComprobante: '1928344',
        codigoDescripcion: 'MOVIMIENTO TIPO CREDITO',
        codigoOpBanco: '76457',
        codigoOperacion: 'IDEP.48',
        codigoSucursal: '00000',
        cuit: '9999999999',
        fechaMovimiento: '2022-11-21T00:00:00',
        fechaCorte: '2022-11-21T00:00:00',
        importe: 102145.95,
        codigoOperacionIb: '',
        denominacion: '',
        isSaldoParcial: false,
        verDetalle: <Icon as={ZoomIn} color={primary[500]} />,
        numeroCorrelativo: '123',
        tipoMovimiento: 'C',
      },
      {
        codigoComprobante: '4152452',
        codigoDescripcion: 'MOVIMIENTO TIPO CREDITO',
        codigoOpBanco: '59546',
        codigoOperacion: 'GTOS.TELEX',
        codigoSucursal: '00000',
        cuit: '9999999999',
        fechaMovimiento: '2022-11-21T00:00:00',
        fechaCorte: '2022-11-21T00:00:00',
        importe: 104235.23,
        codigoOperacionIb: '',
        denominacion: '',
        isSaldoParcial: false,
        verDetalle: <Icon as={ZoomIn} color={primary[500]} />,
        numeroCorrelativo: '123',
        tipoMovimiento: 'C',
      },
      {
        codigoComprobante: '5028276',
        codigoDescripcion: 'MOVIMIENTO TIPO CREDITO',
        codigoOpBanco: '72964',
        codigoOperacion: 'DEV.IB.SIR',
        codigoSucursal: '00000',
        cuit: '9999999999',
        fechaMovimiento: '2022-11-21T00:00:00',
        fechaCorte: '2022-11-21T00:00:00',
        importe: 113445.1,
        codigoOperacionIb: '',
        denominacion: '',
        isSaldoParcial: false,
        verDetalle: <Icon as={ZoomIn} color={primary[500]} />,
        numeroCorrelativo: '123',
        tipoMovimiento: 'C',
      },
      {
        codigoComprobante: '2706527',
        codigoDescripcion: 'MOVIMIENTO TIPO CREDITO',
        codigoOpBanco: '53875',
        codigoOperacion: 'PAGO.REMUN',
        codigoSucursal: '00000',
        cuit: '9999999999',
        fechaMovimiento: '2022-11-21T00:00:00',
        fechaCorte: '2022-11-21T00:00:00',
        importe: 116184.2,
        codigoOperacionIb: '',
        denominacion: '',
        isSaldoParcial: false,
        verDetalle: <Icon as={ZoomIn} color={primary[500]} />,
        numeroCorrelativo: '123',
        tipoMovimiento: 'C',
      },
      {
        codigoComprobante: '5897710',
        codigoDescripcion: 'MOVIMIENTO TIPO CREDITO',
        codigoOpBanco: '43604',
        codigoOperacion: 'COCAPRENCH',
        codigoSucursal: '00000',
        cuit: '9999999999',
        fechaMovimiento: '2022-11-21T00:00:00',
        fechaCorte: '2022-11-21T00:00:00',
        importe: 128391.89,
        codigoOperacionIb: '',
        denominacion: '',
        isSaldoParcial: false,
        verDetalle: <Icon as={ZoomIn} color={primary[500]} />,
        numeroCorrelativo: '123',
        tipoMovimiento: 'C',
      },
      {
        codigoComprobante: '4258469',
        codigoDescripcion: 'MOVIMIENTO TIPO CREDITO',
        codigoOpBanco: '59705',
        codigoOperacion: 'COM.CH/CON',
        codigoSucursal: '00000',
        cuit: '9999999999',
        fechaMovimiento: '2022-11-21T00:00:00',
        fechaCorte: '2022-11-21T00:00:00',
        importe: 161888.37,
        codigoOperacionIb: '',
        denominacion: '',
        isSaldoParcial: false,
        verDetalle: <Icon as={ZoomIn} color={primary[500]} />,
        numeroCorrelativo: '123',
        tipoMovimiento: 'C',
      },
      {
        codigoComprobante: '9765365',
        codigoDescripcion: 'MOVIMIENTO TIPO CREDITO',
        codigoOpBanco: '23690',
        codigoOperacion: 'CO.REM.TER',
        codigoSucursal: '00000',
        cuit: '9999999999',
        fechaMovimiento: '2022-11-21T00:00:00',
        fechaCorte: '2022-11-21T00:00:00',
        importe: 172571.16,
        codigoOperacionIb: '',
        denominacion: '',
        isSaldoParcial: false,
        verDetalle: <Icon as={ZoomIn} color={primary[500]} />,
        numeroCorrelativo: '123',
        tipoMovimiento: 'C',
      },
    ],
  },
  exception: '',
  success: true,
};

export default responseTable;
