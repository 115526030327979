import { IMovimientoViewBoolean, IMovimientoView } from '../interfaces/IResponseTable';

/* eslint-disable import/prefer-default-export */
export const formatDate = (dateFormat: Date) => {
  const date = `${dateFormat.getUTCDate().toString().padStart(2, '0')}/${(dateFormat.getUTCMonth() + 1)
    .toString()
    .padStart(2, '0')}/${dateFormat.getUTCFullYear()}`;
  return date;
};

export const FormatItemIdAnalitycs = (item: IMovimientoViewBoolean | undefined) => {
  let aux = '';

  if (item?.codigoComprobante) {
    aux = 'Codigo de Comprobante-';
  }
  if (item?.codigoOpBanco) {
    aux += 'Codigo de Operacion Banco-';
  }
  if (item?.codigoOperacion) {
    aux += 'Codigo de Operacion-';
  }
  if (item?.codigoSucursal) {
    aux += 'Codigo de Sucursal-';
  }
  if (item?.cuit) {
    aux += 'Cuit-';
  }
  if (item?.denominacion) {
    aux += 'Denominacion-';
  }
  return aux;
};

export const checkCuitNull = (data: Array<IMovimientoView>) => {
  const dataMov = data.map((movimiento: IMovimientoView) => {
    const dataChecked = movimiento.cuit === null || movimiento.cuit === undefined ? { ...movimiento, cuit: '' } : movimiento;
    return dataChecked;
  });
  return dataMov;
};
