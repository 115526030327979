import { v4 as uuidv4 } from 'uuid';
import { IDetailListItem } from '../DetailDrawerComponent/interface';
import messages from '../../../../lang/es.json';

const formatCurrencyDetail = (amount: number, currencyType: string): string => {
  const formattedAmount = amount.toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  if (currencyType === 'USD') {
    return `u$s ${formattedAmount}`;
  }
  return `$ ${formattedAmount}`;
};

const formatDetailListItems = (
  dataDetailMoviment: { texto: string },
  movimiento: {
    numeroCuenta: string;
    denominacion: string;
    fechaMovimiento: string;
    moneda: string;
    importe: number;
    codigoOperacion: string;
  },
): IDetailListItem[] => {
  const { numeroCuenta, denominacion, fechaMovimiento, moneda, importe, codigoOperacion } = movimiento;

  return [
    {
      id: uuidv4(),
      label: messages.detailDrawer.accountNumber,
      value: numeroCuenta,
    },
    {
      id: uuidv4(),
      label: messages.detailDrawer.denomination,
      value: denominacion,
    },
    {
      id: uuidv4(),
      label: messages.detailDrawer.date,
      value: fechaMovimiento,
    },
    {
      id: uuidv4(),
      label: messages.detailDrawer.currency,
      value: moneda,
    },
    {
      id: uuidv4(),
      label: messages.detailDrawer.amount,
      value: formatCurrencyDetail(importe, moneda || '$'),
    },
    {
      id: uuidv4(),
      label: messages.detailDrawer.operationCode,
      value: codigoOperacion,
    },
    {
      id: uuidv4(),
      label: messages.detailDrawer.observations,
      value: dataDetailMoviment.texto,
    },
  ];
};

export { formatDetailListItems, formatCurrencyDetail };
