import { Tooltip, Icon } from '@chakra-ui/react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { ZoomIn } from 'react-feather';
import { themeChakra } from '@wow/themes';
import { IMovimientoView } from '../../../interfaces/IResponseTable';
import { customToolTip, HeaderColumn } from './Styled';
import messages from '../../../lang/es.json';

const columnHelper = createColumnHelper<IMovimientoView>();
const {
  colors: { primary },
} = themeChakra;

export const setEllipsis = (value: string, characters: number): string | JSX.Element => {
  const newValue: string = value !== undefined ? value : '';
  if (characters > 0 && newValue.length > characters) {
    const ellipsis = newValue.slice(0, characters).concat('...');
    return (
      <Tooltip hasArrow label={value} aria-label='A tooltip' placement='top' sx={customToolTip}>
        <span>{ellipsis}</span>
      </Tooltip>
    );
  }

  return value;
};

const setGuion = (value: string | JSX.Element) => {
  if (value === undefined) {
    const guion = '-';
    return <span>{guion}</span>;
  }
  return value;
};

const importFormat = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const headerColumnComponent = (firstRow: string, lastRow?: string) => {
  return (
    <HeaderColumn>
      <span>{firstRow}</span>
      {lastRow !== undefined && <span>{lastRow}</span>}
    </HeaderColumn>
  );
};

const columnsExtractos = (handleDetailMoviment: (movimiento: IMovimientoView) => void): ColumnDef<IMovimientoView, any>[] => [
  columnHelper.accessor('fechaMovimiento', {
    id: 'fechaMovimiento',
    cell: info => setGuion(info.getValue()),
    header: () => headerColumnComponent('Fecha de', 'movimiento'),
  }),
  columnHelper.accessor('codigoOperacionIb', {
    id: 'codigoOperacionIb',
    cell: info => setGuion(setEllipsis(info.getValue(), 22)),
    header: () => headerColumnComponent('Código', 'movimiento'),
  }),
  columnHelper.accessor('importe', {
    id: 'importe',
    cell: info => (
      <span className={info.getValue() >= 0 ? 'positive' : 'negative'}>
        $ {setEllipsis(importFormat.format(info.getValue()).toString(), 22)}
      </span>
    ),
    header: () => headerColumnComponent('Importe'),
  }),
  columnHelper.accessor('codigoDescripcion', {
    id: 'codigoDescripcion',
    cell: info => setEllipsis(info.getValue(), 23),
    header: () => headerColumnComponent('Código', 'descripción'),
  }),
  columnHelper.accessor('codigoComprobante', {
    id: 'codigoComprobante',
    cell: info => setGuion(setEllipsis(info.getValue(), 12)),
    header: () => headerColumnComponent('Código', 'comprobante'),
  }),
  columnHelper.accessor('codigoOpBanco', {
    id: 'codigoOpBanco',
    cell: info => setGuion(setEllipsis(info.getValue().toString(), 5)),
    header: () => headerColumnComponent('Código', 'op. banco'),
  }),
  columnHelper.accessor('cuit', {
    id: 'cuit',
    cell: info => setGuion(setEllipsis(info.getValue().toString(), 12)),
    header: () => headerColumnComponent('CUIT'),
  }),
  columnHelper.accessor('denominacion', {
    id: 'denominacion',
    cell: info => setGuion(setEllipsis(info.getValue(), 3)),
    header: () => headerColumnComponent('Denominación', ''),
  }),
  columnHelper.accessor('codigoSucursal', {
    id: 'codigoSucursal',
    cell: info => <span className='branchCode'>{setGuion(info.getValue())}</span>,
    header: () => headerColumnComponent('Código', 'sucursal'),
  }),
  columnHelper.accessor('codigoOperacion', {
    id: 'codigoOperacion',
    cell: info => setGuion(setEllipsis(info.getValue(), 22)),
    header: () => headerColumnComponent('Concepto', 'código operación'),
  }),
  columnHelper.accessor('verDetalle', {
    id: 'verDetalle',
    cell: ({ row }) => (
      <Icon
        as={ZoomIn}
        color={primary[500]}
        width='18px'
        height='18px'
        cursor='pointer'
        onClick={() => handleDetailMoviment(row.original)}
        role='img'
        aria-label={messages.detailDrawer.sawDetail}
      />
    ),
    header: () => headerColumnComponent('', ''),
  }),
];

export default columnsExtractos;
