import styled, { createGlobalStyle } from 'styled-components';
import { themeChakra } from '@wow/themes';
import { BORDER_LOGO_DETAIL_SELECTED_ACCOUNT } from '../../../../designTokens/colors';

const {
  textStyles: { h6, labelLg },
  colors: { white, black },
} = themeChakra;

const GlobalStyle = createGlobalStyle`
  header.chakra-modal__header {
    button {
      box-shadow: none !important;
    }
  }
`;

const StyledListItem = styled.li`
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0px 12px 0px 12px;
  justify-content: space-between;

  .bank {
    display: flex;
    align-items: center;
  }

  .imgLogo {
    width: 24px;
    height: 24px;
    border: 1px solid ${BORDER_LOGO_DETAIL_SELECTED_ACCOUNT};
    border-radius: 3496.5px;
    margin-left: 4px;
    margin-right: 18px;
  }
`;

const LoadingScreenStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 155;
  top: 0;
  left: 0;
  background: ${white};

  .spinnerClass {
    margin-bottom: 16px;
  }

  .title {
    font-family: ${h6.fontFamily};
    font-weight: ${h6.fontWeight};
    font-size: ${h6.fontSize};
    line-height: ${h6.lineHeight};
    color: ${black};
    margin-bottom: 4px;
  }

  .description {
    font-family: ${labelLg.fontFamily};
    font-weight: ${labelLg.fontWeight};
    font-size: ${labelLg.fontSize};
    line-height: ${labelLg.lineHeight};
    letter-spacing: ${labelLg.letterSpacing};
    color: ${black};
  }
`;

export { GlobalStyle, StyledListItem, LoadingScreenStyles };
