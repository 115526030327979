import styled from 'styled-components';

const WrapperBankNotEnabled = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  flex-direction: column;
`;

const ContainerBankNotEnabled = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ImagenBankNotEnabled = styled.img`
  width: 120px;
  height: 120px;
`;

const TitleBankNotEnabled = styled.div`
  color: #2f3034;
  text-align: center;
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`;

const DescriptionBankNotEnabled = styled.div`
  color: #2f3034;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
`;

export { WrapperBankNotEnabled, ContainerBankNotEnabled, ImagenBankNotEnabled, TitleBankNotEnabled, DescriptionBankNotEnabled };
