import { FiCheck } from 'react-icons/fi';
import {
  BACKGROUND_DEFAULT_COLUMN_CHECKBOX,
  BACKGROUND_NOT_DEFAULT_CHEKEND_COLUMN_CHECKBOX,
  BACKGROUND_NOT_DEFAULT_UNCHEKEND_COLUMN_CHECKBOX,
  BORDER_COLOR_DEFAULT_COLUMN_CHECKBOX,
  COLOR_FICHECK_DEFAULT_COLUMN_CHECKBOX,
  COLOR_FICHECK_NOT_DEFAULT_COLUMN_CHECKBOX,
  COLOR_TITLE_CHECKBOX_DEFAULT_COLUMN_CHECKBOX,
} from '../../../../designTokens/colors';
import { CheckBox, Label, TitleCheckBox, WrapperCheckBox } from './Styled';

function ColumnCheckboxComponent(props: any) {
  const { column, validateIsDefaultValue, changeCheck, getStatusCheck, returnNameCheckBox } = props;
  return (
    <Label>
      <WrapperCheckBox style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        <>
          <CheckBox
            disabled={validateIsDefaultValue(column?.columnDef?.id ? column?.columnDef.id : '')}
            type='checkbox'
            checked={column.getIsVisible()}
            data-testid={column?.id}
            onChange={() => changeCheck(column)}
            style={
              validateIsDefaultValue(column?.columnDef?.id ? column?.columnDef.id : '')
                ? {
                    background: BACKGROUND_DEFAULT_COLUMN_CHECKBOX,
                    pointerEvents: 'none',
                    border: `1px solid ${BORDER_COLOR_DEFAULT_COLUMN_CHECKBOX}`,
                  }
                : getStatusCheck(column)
                  ? { background: BACKGROUND_NOT_DEFAULT_CHEKEND_COLUMN_CHECKBOX }
                  : { background: BACKGROUND_NOT_DEFAULT_UNCHEKEND_COLUMN_CHECKBOX }
            }
          />{' '}
          <div style={{ left: '-23px', top: '4px' }}>
            {validateIsDefaultValue(column?.columnDef?.id ? column?.columnDef.id : '') ? (
              <FiCheck color={COLOR_FICHECK_DEFAULT_COLUMN_CHECKBOX} />
            ) : (
              <FiCheck color={COLOR_FICHECK_NOT_DEFAULT_COLUMN_CHECKBOX} />
            )}
          </div>
        </>

        <TitleCheckBox
          prop={getStatusCheck(column)}
          style={
            validateIsDefaultValue(column?.columnDef?.id ? column?.columnDef.id : '')
              ? { color: COLOR_TITLE_CHECKBOX_DEFAULT_COLUMN_CHECKBOX }
              : {}
          }
        >
          {column?.columnDef?.id && returnNameCheckBox(column?.columnDef?.id)}
        </TitleCheckBox>
      </WrapperCheckBox>
    </Label>
  );
}

export default ColumnCheckboxComponent;
