import { ChakraProvider, ChakraProviderProps, CSSVars, extendTheme, GlobalStyle, PortalManager } from '@chakra-ui/react';
import { EnvironmentProvider } from '@chakra-ui/react-env';
import { toCSSVar } from '@chakra-ui/styled-system';
import { LoadingScreenComponent } from '@wow/loading-screen';
import { useMemo } from 'react';
import { themeChakra } from '@wow/themes';
import { ButtonChakra } from '@wow/button';
import { AlertChakra } from '@wow/alert';
import { TooltipChakra } from '@wow/tooltip';
import { DrawerChakra } from '@wow/drawer';

// Fonts variants from Fontsource
import '@fontsource/red-hat-display/400.css';
import '@fontsource/red-hat-display/500.css';
import '@fontsource/red-hat-display/700.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/400.css';

export interface CustomChakraProviderProps extends ChakraProviderProps {
  addCSSVars?: boolean;
}

/**
 * The global provider that must be added to make all Chakra components
 * work correctly
 */
export function CustomChakraProvider({
  children,
  addCSSVars = false,
  colorModeManager,
  portalZIndex,
  resetCSS = false,
  environment,
  theme = extendTheme({
    ...themeChakra,
    textStyles: {
      ...themeChakra.textStyles,
    },
    colors: {
      ...themeChakra.colors,
    },
    components: {
      Button: ButtonChakra,
      Alert: AlertChakra,
      Tooltip: TooltipChakra,
      LoadingScreen: LoadingScreenComponent,
      Drawer: DrawerChakra,
    },
  }),
  cssVarsRoot,
}: CustomChakraProviderProps): JSX.Element {
  const childrenWithEnvironment = <EnvironmentProvider environment={environment}>{children}</EnvironmentProvider>;
  const computedTheme = useMemo(() => toCSSVar(theme), [theme]);

  return (
    <ChakraProvider
      theme={computedTheme}
      colorModeManager={colorModeManager}
      portalZIndex={portalZIndex}
      environment={environment}
      resetCSS={resetCSS}
    >
      {addCSSVars && <CSSVars root={cssVarsRoot} />}
      {addCSSVars && <GlobalStyle />}
      {portalZIndex ? <PortalManager zIndex={portalZIndex}>{childrenWithEnvironment}</PortalManager> : childrenWithEnvironment}
    </ChakraProvider>
  );
}
