import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

function AppContainer() {
  return (
    <AppContainerPanel>
      <Outlet />
    </AppContainerPanel>
  );
}

export default AppContainer;

const AppContainerPanel = styled.div`
  padding-left: 22px;
  padding-right: 30px;
  padding-top: 20px;
  overflow-x: hidden;
`;
