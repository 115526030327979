// eslint-disable-next-line import/no-unresolved
import { Fonts, PrivateRender } from '@react-ib-mf/style-guide-ui';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/es';

import App from './App';
import { CustomChakraProvider } from './provider/CustomChakraProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: 2,
      retryDelay: attempt => attempt * 1000,
    },
  },
});

export default function Root() {
  return (
    <PrivateRender mode={process.env.NODE_ENV}>
      <QueryClientProvider client={queryClient}>
        <CustomChakraProvider addCSSVars>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='es'>
            <Fonts />
            <App />
          </LocalizationProvider>
        </CustomChakraProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </PrivateRender>
  );
}
