/* eslint-disable import/no-unresolved */
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { WithPermissions } from '@react-ib-mf/style-guide-ui';
import AppContainer from './layouts/AppContainer';
import Extractos from './views/Extractos';
import AppContextProvider from './provider/AppContextProvider';
import { permissionsExtractos } from './utils/permissions';

function App() {
  return (
    <AppContextProvider>
      <Router basename='/consultas'>
        <Routes>
          <Route path='/' element={<AppContainer />}>
            <Route
              path='/extractos'
              element={
                <WithPermissions permission={permissionsExtractos} showErrorPage>
                  <Extractos />
                </WithPermissions>
              }
            />
          </Route>
        </Routes>
      </Router>
    </AppContextProvider>
  );
}

export default App;
