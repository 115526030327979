import styled from 'styled-components';
import { themeChakra } from '@wow/themes';
import {
  BORDER_BOTTOM_CONTAINER_DETAIL_SELECTED_ACCOUNT,
  BORDER_LOGO_DETAIL_SELECTED_ACCOUNT,
  COLOR_DETALLE_GRUPO_DETAIL_SELECTED_ACCOUNT,
  COLOR_DETALLE_GRUPO_SPAN_DETAIL_SELECTED_ACCOUNT,
  COLOR_NOMBRE_BANCO_DETAIL_SELECTED_ACCOUNT,
} from '../../designTokens/colors';

import { IHeader } from '../../interfaces/IExtractos';

const {
  textStyles: { labelMd, titleSmBold, labelLgBold },
} = themeChakra;

function DetailSelectedAccount(props: IHeader) {
  const { title, icon, tipoCuenta, numeroCuenta, denominacion, saldoInicial, saldoFinal, fechaInicial, fechaFinal } = props;
  return (
    <StyleHeader>
      <div className='container'>
        <div className='banco'>
          <img className='logo' alt='' src={icon} />
          <p className='nombre-banco'>{title}</p>
        </div>
        <div className='detalle-cuenta'>
          <p className='detalle-grupo'>
            <span>Tipo y número</span>
            {`${tipoCuenta} ${numeroCuenta}`}
          </p>
          <p className='detalle-grupo'>
            <span>Denominación</span>
            {`${denominacion}`}
          </p>
          <p className='detalle-grupo'>
            <span>Saldo inicial {`${fechaInicial}`}</span>$ {`${saldoInicial}`}
          </p>
          <p className='detalle-grupo'>
            <span>Saldo final {`${fechaFinal}`}</span>$ {`${saldoFinal}`}
          </p>
        </div>
      </div>
    </StyleHeader>
  );
}

export default DetailSelectedAccount;

const StyleHeader = styled.div`
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${BORDER_BOTTOM_CONTAINER_DETAIL_SELECTED_ACCOUNT};
    height: 36px;
  }
  .nombre-banco {
    font-family: ${labelLgBold.fontFamily};
    font-weight: ${labelLgBold.fontWeight};
    font-size: ${labelLgBold.fontSize};
    line-height: ${labelLgBold.lineHeight};
    letter-spacing: ${labelLgBold.letterSpacing};
    color: ${COLOR_NOMBRE_BANCO_DETAIL_SELECTED_ACCOUNT};
    margin-left: 12px;
  }
  .detalle-cuenta {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    .detalle-grupo {
      font-family: ${labelMd.fontFamily};
      font-weight: ${labelMd.fontWeight};
      font-size: ${labelMd.fontSize};
      color: ${COLOR_DETALLE_GRUPO_DETAIL_SELECTED_ACCOUNT};
      span {
        margin-right: 12px;
        color: ${COLOR_DETALLE_GRUPO_SPAN_DETAIL_SELECTED_ACCOUNT};
        font-weight: ${labelMd.fontWeight};
        font-size: ${labelMd.fontSize};
      }
      &:last-child,
      &:nth-child(3) {
        font-weight: ${titleSmBold.fontWeight};
        font-size: ${titleSmBold.fontSize};
      }
      &:last-child {
        margin-left: 23px;
      }
    }
  }
  .banco {
    display: flex;
    align-items: center;
  }
  .logo {
    width: 24px;
    height: 24px;
    border: 1px solid ${BORDER_LOGO_DETAIL_SELECTED_ACCOUNT};
    border-radius: 3496.5px;
    margin-left: 4px;
  }
`;
