/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint no-shadow: 2 */
/* eslint-env es6 */
import { useEffect, useState, useContext } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { useQuery, useQueryClient } from 'react-query';
// eslint-disable-next-line import/no-unresolved
import { useToastOptions, pushAnalyticsEvent } from '@react-ib-mf/style-guide-ui';
import { Button } from '@chakra-ui/react';
import { IReportsComponents } from '../../../interfaces/IReportsComponents';
import { getReports } from '../../../services/apiExtractos';
import { Columns, OptionDropdown, ReportsDropdow } from './styles';
import { AppContext } from '../../../provider/AppContextProvider';
import messages from '../../../lang/es.json';
import encodeDenominacion from '../../../utils/reportComponentUtil';

interface IBlanket {
  onClickBlanket(): void;
}

function Blanket(props: IBlanket) {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions

    <div
      onClick={() => props.onClickBlanket()}
      data-testid='btn-dropdown'
      style={{
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        position: 'fixed',
        zIndex: 0,
        backgroundColor: 'transparent',
      }}
    />
  );
}

function ReportsComponents(props: IReportsComponents) {
  const queryClient = useQueryClient();
  const [openReports, setOpenReports] = useState(false);
  const [report, setReport] = useState('');
  const [, setToastOptions] = useToastOptions();
  const { setReportExtractosFetched, setLoadingReport } = useContext(AppContext) as any;

  const { isFetching: isDataTableFetching, isError } = useQuery(
    ['GetReports'],
    () => {
      const { codigoBanco, fechaFinal, fechaInicial, moneda, numeroCuenta, tipoCuenta } = props.input;
      return getReports({
        numeroCuenta: numeroCuenta || '',
        tipoCuenta: tipoCuenta || '',
        codigoBanco: codigoBanco || '',
        moneda: moneda || '',
        fechaInicial: fechaInicial || '',
        fechaFinal: fechaFinal || '',
        nombreBanco: verifyBBVABank(props.nombreBanco),
        tipoReporte: report,
        denominacion: encodeDenominacion(props.denominacion),
      }).then((res: any) => {
        verfifyTypeReport(res.data);
      });
    },
    { enabled: false },
  );

  setReportExtractosFetched(isDataTableFetching);

  useEffect(() => {
    if (isError) {
      setToastOptions({ description: messages.toast.errorDescription, status: 'error' });
    }
  }, [isError]);

  const verifyBBVABank = (nombreBanco: string) => {
    if (nombreBanco === '*BBVAa') {
      return 'BBVAa';
    }
    return nombreBanco;
  };

  const verfifyTypeReport = (data: any) => {
    switch (report) {
      case 'XLSX':
        return exportExcel(data);
      case 'CSV':
        return exportCsv(data);
      case 'TXT':
        return exportTxt(data);
      case 'PDF':
        return exportPdf(data);
      default:
        return null;
    }
  };
  const exportExcel = (dataExport: any) => {
    const downloadLink = window.document.createElement('a');
    downloadLink.setAttribute('data-testid', 'link-XLSX');
    downloadLink.href = window.URL.createObjectURL(
      new Blob([dataExport], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
    );
    const fecha = new Date();
    const title = `Extractos${fecha.getUTCDate()}/${fecha.getUTCMonth() + 1}/${fecha.getUTCFullYear()}.xlsx`;
    downloadLink.download = title;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    setLoadingReport(false);
  };

  const exportCsv = (data: any) => {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('data-testid', 'link-csv');
    a.setAttribute('href', url);
    const fecha = new Date();
    const title = `Extractos${fecha.getUTCDate()}/${fecha.getUTCMonth() + 1}/${fecha.getUTCFullYear()}.csv`;
    a.setAttribute('download', title);
    a.click();
    setLoadingReport(false);
  };

  const exportTxt = (data: any) => {
    const element = document.createElement('a');
    element.setAttribute('data-testid', 'link-txt');
    const file = new Blob([data], { type: 'text/plain;charset=utf-8' });
    element.href = URL.createObjectURL(file);
    const fecha = new Date();
    const title = `Extractos_${fecha.getUTCDate()}${fecha.getUTCMonth() + 1}${fecha.getUTCFullYear()}.txt`;
    element.download = title;
    document.body.appendChild(element);
    element.click();
    setLoadingReport(false);
  };

  const exportPdf = (data: any) => {
    const file = new Blob([data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.setAttribute('data-testid', 'link-pdf');
    link.href = fileURL;
    const fecha = new Date();
    const title = `Extractos${fecha.getUTCDate()}/${fecha.getUTCMonth() + 1}/${fecha.getUTCFullYear()}.pdf`;
    link.download = title;
    link.click();
    URL.revokeObjectURL(fileURL);
    setLoadingReport(false);
  };

  useEffect(() => {
    if (report) {
      queryClient.fetchQuery(['GetReports']);
    }
  }, [report, queryClient]);

  useEffect(() => {
    const handleClick = () => {
      setOpenReports(!openReports);
    };

    // Agregar el controlador de clic al elemento deseado
    if (openReports) {
      document.getElementById('header-id')?.addEventListener('click', handleClick);
    }

    // Limpia el controlador de clic cuando el componente se desmonta
    return () => {
      document.getElementById('header-id')?.removeEventListener('click', handleClick);
    };
  }, [openReports]);

  return (
    <Columns>
      {openReports ? <Blanket onClickBlanket={() => setOpenReports(!openReports)} /> : null}
      <Button variant='primary-outline' size='md' rightIcon={<FiChevronDown />} onClick={() => setOpenReports(!openReports)}>
        {messages.reportsComponent.title}
      </Button>
      {openReports ? (
        <ReportsDropdow>
          <>
            {setLoadingReport(true)}
            <OptionDropdown
              data-testid='btn-XLSX'
              onClick={() => {
                setReport('XLSX');
                pushAnalyticsEvent({
                  event: 'export_query',
                  content_type: 'Botones seccion Extractos',
                  item_id: 'XLSX',
                });
              }}
            >
              {messages.reportsComponent.verifyType.xlsx}
            </OptionDropdown>
            <OptionDropdown
              data-testid='btn-csv'
              onClick={() => {
                setReport('CSV');
                pushAnalyticsEvent({
                  event: 'export_query',
                  content_type: 'Botones seccion Extractos',
                  item_id: 'CSV',
                });
              }}
            >
              {messages.reportsComponent.verifyType.csv}
            </OptionDropdown>
            <OptionDropdown
              data-testid='btn-txt'
              onClick={() => {
                setReport('TXT');
                pushAnalyticsEvent({
                  event: 'export_query',
                  content_type: 'Botones seccion Extractos',
                  item_id: 'TXT',
                });
              }}
            >
              {messages.reportsComponent.verifyType.txt}
            </OptionDropdown>
            <OptionDropdown
              data-testid='btn-pdf'
              onClick={() => {
                setReport('PDF');
                pushAnalyticsEvent({
                  event: 'export_query',
                  content_type: 'Botones seccion Extractos',
                  item_id: 'PDF',
                });
              }}
            >
              {messages.reportsComponent.verifyType.pdf}
            </OptionDropdown>
          </>
        </ReportsDropdow>
      ) : null}
    </Columns>
  );
}

export default ReportsComponents;
