/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
import { Column, flexRender, getCoreRowModel, RowData, useReactTable } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import { pushAnalyticsEvent } from '@react-ib-mf/style-guide-ui';
import { useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { IDataMovimentDetail, IMovimientoView, IMovimientoViewBoolean, ISaldosParcialesView } from '../../../interfaces/IResponseTable';
import { IDetailListItem } from './DetailDrawerComponent/interface';
import { getColumnMemoFiltered, setColumnMemoFiltered } from '../../../utils/ColumnMemo';
import columnsExtractos from './ColumsConfig';
import ConfigColumnsComponent from './ConfigColumnsComponent';
import { ColumnCheckBox, Row, Table, TableContainer } from './Styled';
import { IDataMovimientos } from '../../../interfaces/ITableComponent';
import ReportsComponents from '../ReportsComponent';
import ColumnCheckboxComponent from './ColumnCheckboxComponent';
import DetailSelectedAccount from '../DetailSelectedAccount';
import messages from '../../../lang/es.json';
import { FormatItemIdAnalitycs, checkCuitNull } from '../../../utils/tablaExtractos';
import DetailDrawerComponent from './DetailDrawerComponent';
import { getDetailMoviment } from '../../../services/apiExtractos';
import { formatDetailListItems } from './utils/formatDetailListItems';

const addSaldoParcialRows = (movimientos: IMovimientoView[], saldoPorDiasView: ISaldosParcialesView[]) => {
  const groupedData: { [key: string]: IMovimientoView[] } = {};
  movimientos.forEach(item => {
    const date = item.fechaCorte;
    if (!groupedData[date]) {
      groupedData[date] = [];
    }
    groupedData[date].push(item);
  });

  const result: IMovimientoView[] = [];

  Object.keys(groupedData).forEach(fechaCorte => {
    const movements = groupedData[fechaCorte];

    movements.forEach(movement => {
      result.push(movement);
    });

    const saldo = saldoPorDiasView.find(s => s.fecha === fechaCorte);

    if (saldo) {
      result.push({
        fechaMovimiento: '',
        fechaCorte,
        codigoOperacionIb: '',
        importe: saldo.saldo,
        codigoDescripcion: '',
        codigoComprobante: '',
        codigoOpBanco: '',
        cuit: '',
        denominacion: '',
        codigoSucursal: '',
        codigoOperacion: '',
        isSaldoParcial: true,
        verDetalle: {},
        numeroCorrelativo: '',
        tipoMovimiento: '',
      });
    }
  });
  return result;
};

function TableComponent(dataTable: IDataMovimientos) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  let columnFiltered = getColumnMemoFiltered('columnFiltered');
  const { movimientos } = dataTable;
  const [data] = useState(() =>
    movimientos !== undefined && dataTable.saldoPorDiasView !== undefined
      ? addSaldoParcialRows([...movimientos], dataTable.saldoPorDiasView)
      : [],
  );
  const [columnsDefault, setColumnsDefault] = useState({});
  const [columnsApplied, setColumnsApplied] = useState({});
  const [columnsChange, setColumnsChange] = useState<IMovimientoViewBoolean>();
  const [showPersonalizar, setShowPersonalizar] = useState<boolean>(false);
  const [columnVisibility, setColumnVisibility] = useState({});

  const [isLargerThan1536] = useMediaQuery('(min-width: 1536px)');
  const [listItems, setListItems] = useState<IDetailListItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDetailMoviment = async (movimiento: IMovimientoView) => {
    const { fechaMovimiento, fechaCorte, importe, numeroCorrelativo, codigoSucursal, tipoMovimiento, codigoOperacion } = movimiento;
    const { codigoBanco, numeroCuenta, tipoCuenta, denominacion } = dataTable.detailAccount;
    const { moneda } = dataTable.input;
    const { page } = dataTable;

    setIsLoading(true);

    try {
      const params: IDataMovimentDetail = {
        codigoBcra: codigoBanco,
        debitoCredito: tipoMovimiento,
        fechaMovimiento,
        fechaValor: fechaCorte,
        importe,
        moneda: moneda || '$',
        numeroCorrelativo: parseInt(numeroCorrelativo, 10),
        numeroCuenta,
        numeroHoja: page,
        sucursal: codigoSucursal,
        tipoCuenta,
      };

      const dataDetailMoviment = await getDetailMoviment(params);

      const detailListItems = formatDetailListItems(dataDetailMoviment, {
        numeroCuenta,
        denominacion,
        fechaMovimiento,
        moneda: moneda || '$',
        importe,
        codigoOperacion,
      });

      setListItems(detailListItems);
      onOpen();
    } catch (error) {
      console.error('Error in handleDetailMoviment:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const wrappedHandleDetailMoviment = (movimiento: IMovimientoView) => {
    handleDetailMoviment(movimiento).catch(error => {
      console.error('Error in wrappedHandleDetailMoviment:', error);
    });
  };

  const table = useReactTable({
    data: checkCuitNull(data),
    columns: columnsExtractos(wrappedHandleDetailMoviment),
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      columnVisibility,
    },
  });

  const { getAllLeafColumns } = table;

  const columnsDefaultTable = (column: Column<RowData>) => {
    if (
      [
        'fechaMovimiento',
        'codigoOperacionIb',
        'importe',
        'codigoDescripcion',
        'codigoComprobante',
        'codigoOpBanco',
        'cuit',
        'denominacion',
        'codigoSucursal',
        'codigoOperacion',
      ].includes(column.id)
    ) {
      return (
        <ColumnCheckBox key={column.id}>
          <ColumnCheckboxComponent
            column={column}
            validateIsDefaultValue={validateIsDefaultValue}
            changeCheck={changeCheck}
            getStatusCheck={getStatusCheck}
            returnNameCheckBox={returnNameCheckBox}
          />
        </ColumnCheckBox>
      );
    }
    return false;
  };

  const validateIsDefaultValue = (nameColumn: string) => {
    if (!['fechaMovimiento', 'codigoOperacionIb', 'importe', 'codigoDescripcion'].includes(nameColumn)) {
      validateIsDefaultColumns();
      return false;
    }
    return true;
  };

  const returnNameCheckBox = (idCheck: string) => {
    if (idCheck === 'fechaMovimiento') {
      return messages.columnsConfig.fechaMovimiento;
    }
    if (idCheck === 'codigoOperacionIb') {
      return messages.columnsConfig.codigoMovimiento;
    }
    if (idCheck === 'importe') {
      return messages.columnsConfig.importe;
    }
    if (idCheck === 'codigoDescripcion') {
      return messages.columnsConfig.codDescripcion;
    }
    if (idCheck === 'codigoComprobante') {
      return messages.columnsConfig.codComprobante;
    }
    if (idCheck === 'codigoOpBanco') {
      return messages.columnsConfig.codOperacionBanco;
    }
    if (idCheck === 'cuit') {
      return messages.columnsConfig.cuit;
    }
    if (idCheck === 'denominacion') {
      return messages.columnsConfig.denominacion;
    }
    if (idCheck === 'codigoSucursal') {
      return messages.columnsConfig.codSucursal;
    }
    if (idCheck === 'codigoOperacion') {
      return messages.columnsConfig.codOperacion;
    }

    return '';
  };

  const defaultColumn: IMovimientoViewBoolean = {
    codigoComprobante: true,
    codigoOpBanco: true,
    cuit: true,
    denominacion: true,
    codigoOperacion: false,
    codigoSucursal: false,
  };

  const defaultValuesColumn: IMovimientoViewBoolean = columnFiltered ? JSON.parse(columnFiltered) : defaultColumn;

  const resetDefaultColumns = () => {
    columnFiltered = JSON.stringify(defaultColumn);
    setColumnMemoFiltered('columnFiltered', columnFiltered);
    setColumnVisibility(JSON.parse(columnFiltered));
    setColumnsDefault(columnFiltered);
    setColumnsApplied(columnFiltered);
    setColumnsChange(JSON.parse(columnFiltered));
    setShowPersonalizar(false);
  };

  const resetAllStates = () => {
    setColumnVisibility(defaultValuesColumn);
    setColumnsDefault(JSON.stringify(defaultValuesColumn));
    setColumnsApplied(JSON.stringify(defaultValuesColumn));
    setColumnsChange(defaultValuesColumn);
    setShowPersonalizar(false);
  };

  useEffect(() => {
    resetAllStates();
  }, []);

  const changeCheck = (column: Column<RowData>) => {
    switch (column.id) {
      case 'codigoComprobante':
        setColumnsChange({ ...columnsChange, codigoComprobante: !columnsChange?.codigoComprobante });
        break;
      case 'codigoOpBanco':
        setColumnsChange({ ...columnsChange, codigoOpBanco: !columnsChange?.codigoOpBanco });
        break;
      case 'cuit':
        setColumnsChange({ ...columnsChange, cuit: !columnsChange?.cuit });
        break;
      case 'denominacion':
        setColumnsChange({ ...columnsChange, denominacion: !columnsChange?.denominacion });
        break;
      case 'codigoOperacion':
        setColumnsChange({ ...columnsChange, codigoOperacion: !columnsChange?.codigoOperacion });
        break;
      case 'codigoSucursal':
        setColumnsChange({ ...columnsChange, codigoSucursal: !columnsChange?.codigoSucursal });
        break;
      default:
        break;
    }
  };

  const validateIsDefaultColumns = () => {
    const columnState = JSON.stringify(columnsChange);

    if (columnsDefault && columnState === JSON.stringify(defaultColumn)) {
      return true;
    }
    return false;
  };

  const applyChange = () => {
    const columnState = JSON.stringify(columnsChange);
    setColumnsApplied(columnState);
    setColumnVisibility(JSON.parse(columnState));
    setColumnMemoFiltered('columnFiltered', columnState);
    setShowPersonalizar(false);
    return true;
  };

  const validateColumnsApplied = () => {
    const columnState = JSON.stringify(columnsChange);
    if (columnState === columnsApplied) {
      return true;
    }
    return false;
  };

  const getStatusCheck = (column: Column<RowData>) => {
    switch (column.id) {
      case messages.columnsConfig.statusCheck.codComprobante:
        if (columnsChange?.codigoComprobante) return true;
        break;
      case messages.columnsConfig.statusCheck.codOpBanco:
        if (columnsChange?.codigoOpBanco) return true;
        break;
      case messages.columnsConfig.statusCheck.cuit:
        if (columnsChange?.cuit) return true;
        break;
      case messages.columnsConfig.statusCheck.denominacion:
        if (columnsChange?.denominacion) return true;
        break;
      case messages.columnsConfig.statusCheck.codOperacion:
        if (columnsChange?.codigoOperacion) return true;
        break;
      case messages.columnsConfig.statusCheck.codSucursal:
        if (columnsChange?.codigoSucursal) return true;
        break;
      default:
        return false;
    }
    return false;
  };

  function funcPushAnalitycs(event: string, content: string, item: string) {
    pushAnalyticsEvent({
      event,
      content_type: content,
      item_id: item,
    });
  }
  const importFormat = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  const formatCurrency = (value: number) => {
    return `$ ${importFormat.format(value)}`;
  };

  const { input, nombreBanco } = dataTable;
  const { title, tipoCuenta, numeroCuenta, denominacion, saldoInicial, saldoFinal, fechaInicial, fechaFinal, icon } =
    dataTable.detailAccount;

  return (
    <>
      <DetailDrawerComponent
        isOpen={isOpen}
        onClose={onClose}
        icon={icon}
        nombreBanco={nombreBanco}
        titleDrawer={messages.detailDrawer.statementDetails}
        buttonPrimary={messages.detailDrawer.downloadVoucher}
        listItems={listItems}
        isLoading={isLoading}
      />
      <Row>
        <ReportsComponents input={input} nombreBanco={nombreBanco} denominacion={denominacion} />
        <ConfigColumnsComponent
          icon=''
          getAllLeafColumns={() => getAllLeafColumns()}
          columnsDefaultTable={(e: Column<RowData>) => columnsDefaultTable(e)}
          setShowPersonalizar={(e: boolean) => {
            setShowPersonalizar(e);
            if (e) {
              funcPushAnalitycs('select_content', 'Botones seccion Extractos', 'Configura tu consulta');
            }
          }}
          showPersonalizar={showPersonalizar}
          validateIsDefaultColumns={() => validateIsDefaultColumns()}
          resetDefaultColumns={() => {
            resetDefaultColumns();
            funcPushAnalitycs('reset_columns', 'Botones seccion Extractos', 'Restablecer');
          }}
          validateColumnsApplied={() => validateColumnsApplied()}
          applyChange={() => {
            applyChange();
            funcPushAnalitycs('add_column', 'Botones seccion Extractos', FormatItemIdAnalitycs(columnsChange));
          }}
        />
      </Row>
      <DetailSelectedAccount
        icon={icon}
        title={title}
        tipoCuenta={tipoCuenta}
        numeroCuenta={numeroCuenta}
        denominacion={denominacion}
        fechaInicial={fechaInicial}
        fechaFinal={fechaFinal}
        saldoInicial={saldoInicial}
        saldoFinal={saldoFinal}
        idBanco={input?.codigoBanco}
      />
      <div style={{ overflowX: 'scroll' }}>
        <TableContainer isLargerThan1536={isLargerThan1536}>
          <Table>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th key={header.id}>
                      <span className={header.id === 'importe' ? 'columnImporte' : ''}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <FiChevronUp />,
                          desc: <FiChevronDown />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => {
                const { original } = row;
                const { isSaldoParcial } = original;
                const visibleCells = row.getVisibleCells();

                return (
                  <tr
                    key={row.id || `row-${index}`}
                    style={{
                      fontWeight: isSaldoParcial ? 'bold' : 'normal',
                      backgroundColor: isSaldoParcial ? '#f0f0f0' : 'transparent',
                      height: isSaldoParcial ? '36px' : '40px',
                    }}
                  >
                    {isSaldoParcial && (
                      <td colSpan={visibleCells.length} style={{ textAlign: 'left', fontWeight: 'bold' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '0 20px' }}>
                          <span>Saldo al {original.fechaCorte}</span>
                          <span>{formatCurrency(original.importe)}</span>
                        </div>
                      </td>
                    )}
                    {!isSaldoParcial &&
                      visibleCells.map(cell => (
                        <td
                          key={cell.id}
                          colSpan={isSaldoParcial && cell.column.id === 'fechaMovimiento' ? 2 : 1}
                          style={{ textAlign: 'center' }}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default TableComponent;
